import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import dateFormat from 'dateformat';
import Lottie from 'lottie-react';
import React, { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
// Animation et CSS
import squirrelSleeping from '../../assets/graphics/lottie/lf30_editor_qru8ucal.json';
// Appel des composants
import { Button } from '../../components/Button';
import DoughnutChart from '../../components/charts/DoughnutChart';
import { Dialog } from '../../components/Dialog';
import Dropdown from '../../components/Dropdown';
import Input from '../../components/Input/Input';
import LabeledContainer from '../../components/LabeledContainer';
import { Menu } from '../../components/Menu';
import Select from '../../components/Select';
import SuperInput from '../../components/SuperInput';
// Appel des constantes
import { MONTHS, MONTH_PLAN, ROLES_USER } from '../../constants/constants';
// Mutations
import { ADD_BUDGET, REM_BUDGET, SET_BUDGET } from '../../graphql/mutations/budget';
// Appel des données en base
import { GET_BUDGET, GET_BUDGETS_IDS_ACCOUNTINGYEAR_USER } from '../../graphql/queries/budget';
import { GET_COMPUT_PARAMS } from '../../graphql/queries/computationsParameters';
import { GET_ASSOCIATES_NAMES, GET_NB_USERS_FACILITY, GET_USER } from '../../graphql/queries/users';
// Authentification
import { AuthContext } from '../../providers/AuthProvider/authProvider';
import { SpecificatorsContext } from '../../providers/SpecificatorsProvider/specificatorsProvider';
// Outils
import { hasErrors, onError, renderErrors } from '../../utils/errorHandler';
import { useFlaggedState } from '../../utils/hooks';
import {
  barchartDatasetDetails,
  chargesDetails,
  doughnutGraphDatasetDetails,
  getMonthDetails,
  otherIncomesDetails,
  yearlyBillingDetails,
} from '../../utils/simulation';
import { initialiserDefaultSpec } from '../../utils/specificators';
import { isInCurrentFiscalYear, roleHasAccessTo, round } from '../../utils/utils';

export const Budget = () => {
  const { user } = useContext(AuthContext);
  const [errors, setErrors] = useState();
  const [errorsAdd, setErrorsAdd] = useState();

  // budget state
  const [yearPlan, setYearPlan] = useState({});
  const [budget, setBudget, budgetFlag, setBudgetFlag] = useFlaggedState({});

  // loading specificators
  const {
    loaded: { budget: budgetSpecLoaded, yearPlan: yearPlanSpecLoaded, otherIncomes: otherIncomesSpecLoaded },
    specificators: {
      budget: budgetSpecificator,
      monthPlan: monthPlanSpecificator,
      yearPlan: yearPlanSpecificator,
      otherIncomes: otherIncomesSpecificator,
    },
  } = useContext(SpecificatorsContext);

  // action states
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  // show states
  const [showMonthDetails, setShowMonthDetails] = useState(false);
  const [showOtherIncomes, setShowOtherIncomes] = useState(false);
  const [showCharges, setShowCharges] = useState(false);

  // select states
  const [selectedBudget, setSelectedBudget] = useState(-1);
  const [budgetsIdsAccountingYear, setBudgetsIdsAccountingYear] = useState([]);
  const [selectedAssociate, setSelectedAssociate] = useState(-1);
  const [associatesNames, setAssociatesNames] = useState([]);

  const budgetId = (value) => {
    return budgetsIdsAccountingYear && budgetsIdsAccountingYear[value] && budgetsIdsAccountingYear[value].id;
  };

  const associateId = (value) => {
    return (associatesNames && associatesNames[value] && associatesNames[value].id) || (user && user.id);
  };

  // charges state
  const [charges, setCharges, chargesFlag] = useFlaggedState({});

  // parameters used in the calculations
  const [nbUsersSameFacility, setNbUsersSameFacility] = useState(0);
  const [userInfos, setUserInfos, userInfosFlag] = useFlaggedState({});
  const [computParams, setComputParams, computParamsFlag] = useFlaggedState({});

  // charts states
  const [chargedWage, setChargedWage] = useState(0);
  const [doughtnutData, setDoughtnutData] = useState({});
  const [barchartDatasets, setBarchartDatasets] = useState([]);
  const [yearlyBilling, setYearlyBilling, yearlyBillingFlag] = useFlaggedState({
    value: 115500,
  });

  // month details
  const [monthDetails, setMonthDetails] = useState({});
  const [selectedMonthDetails, setSelectedMonthDetails, selectedMonthDetailsFlag] = useFlaggedState({});

  // BEGIN budget state logic

  const setDaysByYear = (newYearPlan) => {
    setYearPlan(newYearPlan);
    const selectedDays = Object.values(budget.monthsPlan || {})
      .map((m) => (m.selected ? m.days : 0))
      .reduce((p, c) => p + c, 0);
    // number of days - number of days that will not be changed
    const remainingDays = newYearPlan.days - selectedDays;
    if (remainingDays > 0) {
      const nbNotSelected = 12 - Object.values(budget.monthsPlan).filter((m) => m.selected).length;
      const days = Number.parseInt(remainingDays / nbNotSelected);
      // computes the sum of the days of the selected months
      let restOfDays = remainingDays % nbNotSelected;
      const newSimulation = JSON.parse(JSON.stringify(budget));
      Object.keys(MONTHS).forEach((m) => {
        if (newSimulation.monthsPlan[m] && !newSimulation.monthsPlan[m].selected) {
          newSimulation.monthsPlan[m].days = days;
          if (restOfDays > 0) newSimulation.monthsPlan[m].days++;
          restOfDays--;
        }
      });
      setBudget({ ...newSimulation });
    }
  };

  const setDaysByMonth = (newBudget) => {
    if (yearPlanSpecificator) {
      const days = Object.keys(MONTHS)
        .map((m) => newBudget.monthsPlan[m].days)
        .reduce((p, c) => p + c, 0);
      const specDays = yearPlanSpecificator.fields.find((v) => v.name === 'days');
      if (specDays.max && days <= specDays.max && specDays.min && days >= specDays.min) {
        setYearPlan((oldState) => ({ ...oldState, days }));
        setBudget({ ...newBudget });
      }
    }
  };

  const setBudgetByYear = (newYearPlan) => {
    setYearPlan({ ...newYearPlan });
    let newMonthPlan = { ...budget.monthsPlan };
    Object.keys(MONTHS).forEach((m) =>
      Object.keys(newYearPlan)
        .filter((key) => key !== 'days')
        .forEach((keyPlan) => {
          if (!newMonthPlan[m].selected) newMonthPlan[m][keyPlan] = newYearPlan[keyPlan];
        })
    );
    setBudget((oldState) => ({
      ...oldState,
      monthsPlan: newMonthPlan,
    }));
  };

  const changeBudget = (budg) => {
    if(associate){
      setBudget({
        ...budg,
        otherIncomes: {
          ...budg.otherIncomes,
          ...otherIncomesDetails(
            associate.user.contribution,
            chargedWage,
            yearPlan.days,
            budg.otherIncomes && budg.otherIncomes.mealTicket
          ),
        },
      });
      const newYearPlan =
        Object.values(budg.monthsPlan).filter((mP) => !mP.selected)[0] ||
        Object.values(budg.monthsPlan)[0];
      const days = Object.values(budg.monthsPlan)
        .map((mP) => mP.days)
        .reduce((p, c) => p + c, 0);
  
      setYearPlan((oldState) => ({
        ...oldState,
        ...newYearPlan,
        days,
      }));
    }
  };

  // END budget state logic

  // BEGIN queries and mutations

  //------ begin fetch parameters

  useQuery(GET_COMPUT_PARAMS, {
    variables: {
      year: new Date().getFullYear(),
      facility: userInfos.facility,
    },
    skip: !userInfos.facility,
    onCompleted: (data) => {
      if (data) {
        setComputParams({
          ...data.computParams,
        });
      } else {
        console.log("The computations parameters haven't been defined for the current year");
      }
    },
    onError: (err) => {
      onError(setErrors, err);
    },
  });

  // Récupération des infos de l'utilisateur connecté. Différent de l'associé selectionné.
  useQuery(GET_USER, {
    variables: { id: user.id },
    skip: !user.id || user.id.length < 1,
    onCompleted: (data) => {
      const userData = data && data.user;
      if (userData) {
        setUserInfos({ ...userData });
      }
    },
    onError: (err) => onError(setErrors, err),
  });

  useQuery(GET_NB_USERS_FACILITY, {
    variables: { facilityId: userInfos.facility },
    skip: !userInfos.facility || userInfos.facility.length < 1,
    onCompleted: (data) => {
      if (data && data.nbUsersFacility != null) {
        setNbUsersSameFacility(data.nbUsersFacility);
      }
    },
    onError: (err) => onError(setErrors, err),
  });
  //------ end fetch parameters

  const { loading: loadingAssociatesNames } = useQuery(GET_ASSOCIATES_NAMES, {
    variables: { facilityId: userInfos.facility, userId: user.id },
    skip: !user.id || !userInfos.facility || user.id.length < 1,
    onCompleted: (data) => {
      if (data && data.associatesNames) {
        delete data.associatesNames.__typename;
        setAssociatesNames([...data.associatesNames]);
      }
    },
    onError: (err) => onError(setErrors, err),
  });

  const { loading: loadingbudgetsIdsAccountingYear, refetch: refetchbudgetsIdsAccountingYear } = useQuery(
    GET_BUDGETS_IDS_ACCOUNTINGYEAR_USER,
    {
      variables: {
        userId: associateId(selectedAssociate),
      },
      onCompleted: (data) => {
        if (data && data.budgetsIdsAccountingYear) {
          delete data.budgetsIdsAccountingYear.__typename;
          setBudgetsIdsAccountingYear([...data.budgetsIdsAccountingYear]);
          setErrors(null);
        }
      },
      onError: (err) => {
        onError(setErrors, err);
      },
    }
  );

  const { loading: loadingBudget, refetch: refetchBudget } = useQuery(GET_BUDGET, {
    variables: {
      userId: associateId(selectedAssociate),
      id: budgetId(selectedBudget),
    },
    skip: selectedBudget < 0 || !budgetId(selectedBudget),
    onCompleted: (data) => {
      if (data && data.budgetById) {
        const { budgetById } = data;
        delete budgetById.__typename;
        delete budgetById.id;
        delete budgetById.user;
        delete budgetById.createdAt;
        delete budgetById.updatedAt;
        changeBudget(budgetById);
        setErrors(null);
      }
    },
    onError: (err) => {
      onError(setErrors, err);
    },
  });

  // mutations

  const [updateBudget, { loading: loadingUpdateBudget }] = useMutation(SET_BUDGET, {
    variables: {
      userId: associateId(selectedAssociate),
      id: budgetId(selectedBudget),
      budgetInput: budget,
    },
    skip: selectedBudget < 0 || !budgetId(selectedBudget),
    onCompleted: async ({ setBudget: newBudget }) => {
      if (newBudget) {
        delete newBudget.__typename;
        delete newBudget.id;
        delete newBudget.createdAt;
        delete newBudget.user;
        delete newBudget.updatedAt;
        changeBudget(newBudget);
        setErrors(null);
        await refetchbudgetsIdsAccountingYear();
      }
    },
    onError: (err) => {
      onError(setErrors, err);
    },
  });

  const [addBudget, { loading: loadingAddBudget }] = useMutation(ADD_BUDGET, {
    variables: {
      userId: associateId(selectedAssociate),
    },
    onCompleted: async (data) => {
      setIsAdding(false);
      if (data && data.addBudget) {
        await refetchbudgetsIdsAccountingYear();
        setBudget(data.addBudget);
        setErrorsAdd(null);
        setSelectedBudget(0);
      }
    },
    onError: (err) => onError(setErrorsAdd, err),
  });

  const [deleteBudget, { loading: loadingDeleteBudget }] = useMutation(REM_BUDGET, {
    variables: {
      userId: associateId(selectedAssociate),
      id: budgetId(selectedBudget),
    },
    onCompleted: async (data) => {
      setIsDeleting(false);
      if (data) {
        await refetchbudgetsIdsAccountingYear();
        setSelectedBudget(-1);
        setErrors(null);
      }
    },
    onError: (err) => onError(setErrors, err),
  });

  const loadingOperation = loadingAddBudget || loadingBudget || loadingDeleteBudget || loadingUpdateBudget;

  // END queries and mutations

  // BEGIN Select change handlers
  // séléction de l'associé
  const [selectAssociate, { data: associate }] = useLazyQuery(GET_USER);

  const onChangeSelectedAssociates = async (event) => {
    const value = event.target.value;
    setSelectedAssociate(value);
    setSelectedBudget(-1);
    selectAssociate({ variables: { id: associateId(value) } });
    await refetchbudgetsIdsAccountingYear();
  };

  const onChangeSelectedBudget = async (event) => {
    const value = event.target.value;
    setSelectedBudget(value);
    setBudgetFlag(null);
    if (value > 0) await refetchBudget();
    await refetchbudgetsIdsAccountingYear();
  };
  // END Select change handlers

  // BEGIN budget useEffects

  // charges details
  useEffect(() => {
    if(associate){setCharges({
      ...chargesDetails(
        associate.user,
        computParams,
        yearlyBilling.value,
        nbUsersSameFacility
      ),
    });}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfosFlag, computParamsFlag, yearlyBillingFlag, nbUsersSameFacility]);

  // other incomes details
  useEffect(() => {
    if(associate) {
    setBudget({
      ...budget,
      otherIncomes: {
        ...budget.otherIncomes,
        ...otherIncomesDetails(
          associate.user.contribution,
          chargedWage,
          yearPlan.days,
          budget.otherIncomes && budget.otherIncomes.mealTicket
        ),
      },
    });
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargedWage]);

  // yearly billing
  useEffect(() => {
    setYearlyBilling({ ...yearlyBillingDetails(budget) });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetFlag]);

  // month details
  useEffect(() => {
    // sets the month details panel with the updated values
    if (
      selectedMonthDetails != null &&
      Object.keys(MONTHS)[selectedMonthDetails] != null &&
      budget.monthsPlan &&
      budget.monthsPlan[Object.keys(MONTHS)[selectedMonthDetails]]
    ) {
      setMonthDetails(
        getMonthDetails(selectedMonthDetails, budget.monthsPlan, budget.otherIncomes, computParams, charges)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetFlag, computParamsFlag, selectedMonthDetailsFlag]);

  // END budget useEffects

  // BEGIN charts useEffects

  // doughnut graph data
  useEffect(() => {
    const newDoughtnutDataset = doughnutGraphDatasetDetails(
      budget,
      computParams,
      budget.otherIncomes,
      charges,
      yearlyBilling.value
    );
    setChargedWage(newDoughtnutDataset.chargedWage.value);
    const doughnutData = Object.values(newDoughtnutDataset);
    const clickHandlers = Object.keys(newDoughtnutDataset).map((key) => {
      switch (key) {
        case 'otherIncomes':
          return () => {
            setShowMonthDetails(false);
            setShowOtherIncomes((prevState) => !prevState);
          };
        case 'totalCharges':
          return () => {
            setShowMonthDetails(false);
            setShowCharges((prevState) => !prevState);
          };
        default:
          return null;
      }
    });
    setDoughtnutData({
      labels: doughnutData.map((v) => v.label),
      descriptions: doughnutData.map((v) => v.description),
      legendClickHandlers: clickHandlers,
      datasets: [
        {
          label: 'Simulation',
          backgroundColor: doughnutData.map((v) => v.color),
          data: doughnutData.map((v) => round(v.value, 0)),
          outlabels: {
            color: 'rgb(0,0,0)',
          },
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetFlag, computParamsFlag, chargesFlag, yearlyBillingFlag]);

  // histogram variables
  useEffect(() => {
    setBarchartDatasets(barchartDatasetDetails(budget.monthsPlan, budget.otherIncomes, computParams, charges));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetFlag, computParamsFlag]);

  // END charts useEffects

  // BEGIN initialising states using specificators

  // budget
  useEffect(() => {
    if (selectedBudget < 0 && budgetSpecLoaded) {
      const budg = initialiserDefaultSpec({}, budgetSpecificator);
      setBudget((prevState) => ({ ...prevState, ...budg }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBudget, budgetSpecLoaded]);

  // budget year plan
  useEffect(() => {
    if (selectedBudget < 0 && yearPlanSpecLoaded) {
      const defaultYearPlan = initialiserDefaultSpec({}, yearPlanSpecificator);
      let monthsPlan = {};
      let days = Number.parseInt(defaultYearPlan.days / 12);
      let restOfDays = defaultYearPlan.days % 12;
      Object.keys(MONTHS).forEach((m) => {
        monthsPlan[m] = { ...defaultYearPlan, days };
        if (restOfDays > 0) monthsPlan[m].days++;
        restOfDays--;
      });
      setYearPlan(defaultYearPlan);
      setBudget((prevState) => ({
        ...prevState,
        monthsPlan,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBudget, yearPlanSpecLoaded]);

  // budget other incomes
  useEffect(() => {
    if (selectedBudget < 0 && otherIncomesSpecLoaded && associate) {
      const otherIncomesDef = initialiserDefaultSpec(
        budget.otherIncomes,
        otherIncomesSpecificator
      );
      // updating state
      setBudget((prevState) => ({
        ...prevState,
        otherIncomes: {
          ...prevState.otherIncomes,
          ...otherIncomesDef,
          ...otherIncomesDetails(
            associate.user.contribution,
            chargedWage,
            yearPlan.days,
            otherIncomesDef && otherIncomesDef.mealTicket
          ),
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBudget, otherIncomesSpecLoaded]);

  // END initialising states using specificators

  const checkSubmissible = () => {
    const budgetHasError = hasErrors(budget, budgetSpecificator);
    const yearPlanHasError = hasErrors(yearPlan, yearPlanSpecificator);
    const monthsPlanHasError = Object.keys(MONTHS)
      .map((k) => budget.monthsPlan && hasErrors(budget.monthsPlan[k], monthPlanSpecificator))
      .reduce((p, c) => p || c);

    return !loadingOperation && selectedBudget >= 0 && !budgetHasError && !yearPlanHasError && !monthsPlanHasError;
  };

  const submitDisabled = !checkSubmissible();

  const onSubmit = (event) => {
    event.preventDefault();
    if (!submitDisabled) updateBudget();
  };

  function onClickSubmit() {
    checkSubmissible();
  }

  /**
   *
   * @param {} name
   * @param {Number} yearPlan from specificators(on server)
   * @return {{cases}}
   * Dynamically handles max OtherIncomes's values
   */
  const getOtherIncomesMaximumValue = (name) => {
    switch (name) {
      case 'mealTicket':
        return (yearPlan.days * computParams.mealTicket).toFixed(2);
      case 'mealPack':
        return;
      default:
        return;
    }
  };

  /**
   *
   * @param {Boolean} name from specificators(on server)
   * @return {{cases}}
   * Disabled or enabled an element
   */
  const getOtherIncomesActivator = (name) => {
    if (associate) {
      switch (name) {
        // if mealTicket is true, disabled is false, and vice versa.
        case "mealTicket":
          return !associate.user.mealTicket;
        default:
          return false;
      }
    }
  };

  return (
    <div className="row whole-height fill">
      <Menu />
      <main className="block column fill">
        <div className={`centered-block fill`}>
          <div className="white-container fill" style={{ alignItems: 'flex-start' }}>
            <h1 className="w-100 titlePage">Budgets</h1>
            <hr></hr>
            <form onSubmit={onSubmit} className="column w-100 marg-between-b">
              <section className="row marg-b align-b marg-between-r">
                {user.role === ROLES_USER.DIRECTOR && (
                  <Select
                    label="Associé"
                    defaultOption={"Selection de l'associé"}
                    name="name"
                    value={selectedAssociate}
                    values={associatesNames.map((v) => `${v.firstname} ${v.lastname}`)}
                    onChange={onChangeSelectedAssociates}
                    loading={loadingAssociatesNames}
                  />
                )}
                {user.role === ROLES_USER.DIRECTOR && selectedAssociate >= 0 && budgetsIdsAccountingYear.length === 0 && (
                  <Button
                    label={'Créer un budget'}
                    plain={true}
                    callback={() => {
                      setIsAdding(true);
                    }}
                  />
                )}
                {budgetsIdsAccountingYear.length !== 0 && (
                  <Select
                    label="Exercices"
                    defaultOption={"Selection de l'exercice"}
                    name="budget"
                    value={selectedBudget}
                    values={budgetsIdsAccountingYear.map((v) => `${v.accountingYear}`)}
                    onChange={onChangeSelectedBudget}
                    loading={loadingbudgetsIdsAccountingYear}
                  />
                )}
                {user.role === ROLES_USER.DIRECTOR && selectedBudget >= 0 && (
                  <Button
                    label={'Supprimer'}
                    plain={true}
                    callback={() => {
                      setIsDeleting(true);
                    }}
                  />
                )}
                {user.role === ROLES_USER.DIRECTOR &&
                  selectedBudget >= 0 &&
                  budgetsIdsAccountingYear[selectedBudget] &&
                  !isInCurrentFiscalYear(budgetsIdsAccountingYear[selectedBudget].accountingYear) && (
                    <Button
                      label={'Ajouter une période'}
                      plain={true}
                      callback={() => {
                        setIsAdding(true);
                      }}
                    />
                  )}
                {user.role === ROLES_USER.DIRECTOR &&
                  !submitDisabled &&
                  roleHasAccessTo(user.role, ROLES_USER.DIRECTOR) && (
                    <div className="row-reverse fill align-b">
                      <Input value={'Enregistrer'} type="submit" onClick={onClickSubmit} />
                    </div>
                  )}
              </section>

              {/** ---
               BUDGET TOOL
               */}
              {selectedBudget !== -1 ? (
                <section className="simulationTool">
                  <div className="column w-100 wrapper marg-between-b">
                    <div className="row w-100 marg-between-r marg-between-b space-between wrapper">
                      <div className="labeled-block flex-3 marg-s-auto">
                        <h3>Moyenne annuelle</h3>
                        <div className="row space-between marg-between-r">
                          <div className="column fill">
                            <SuperInput
                              label="Nombre de jours travaillés"
                              type={'range'}
                              state={yearPlan}
                              setState={setDaysByYear}
                              specificator={yearPlanSpecificator}
                              path={`days`}
                              errors={errors}
                              setErrors={setErrors}
                              nameclass={'fill'}
                              disabled={user.role !== ROLES_USER.DIRECTOR}
                            />
                            <SuperInput
                              label="Salaire mensuel net optimal"
                              type={'range'}
                              state={yearPlan}
                              setState={setBudgetByYear}
                              specificator={yearPlanSpecificator}
                              path={`wage`}
                              errors={errors}
                              setErrors={setErrors}
                              nameclass={'fill'}
                              disabled={user.role !== ROLES_USER.DIRECTOR}
                            />
                            <SuperInput
                              label="Km domicile <-> mission A/R"
                              type={'range'}
                              state={yearPlan}
                              setState={setBudgetByYear}
                              specificator={yearPlanSpecificator}
                              path={`kmDay`}
                              errors={errors}
                              setErrors={setErrors}
                              nameclass={'fill'}
                              disabled={user.role !== ROLES_USER.DIRECTOR}
                            />
                            <SuperInput
                              label="TJM de la mission"
                              type={'range'}
                              state={yearPlan}
                              setState={setBudgetByYear}
                              specificator={yearPlanSpecificator}
                              path={`billing`}
                              errors={errors}
                              setErrors={setErrors}
                              nameclass={'fill'}
                              disabled={user.role !== ROLES_USER.DIRECTOR}
                            />
                          </div>
                          <div className="column centered">
                            <SuperInput
                              state={yearPlan}
                              setState={setDaysByYear}
                              specificator={yearPlanSpecificator}
                              path={`days`}
                              errors={errors}
                              setErrors={setErrors}
                              nameclass="small"
                              disabled={user.role !== ROLES_USER.DIRECTOR}
                            />
                            <SuperInput
                              state={yearPlan}
                              setState={setBudgetByYear}
                              specificator={yearPlanSpecificator}
                              path={`wage`}
                              errors={errors}
                              setErrors={setErrors}
                              nameclass="small"
                              disabled={user.role !== ROLES_USER.DIRECTOR}
                            />
                            <SuperInput
                              state={yearPlan}
                              setState={setBudgetByYear}
                              specificator={yearPlanSpecificator}
                              path={`kmDay`}
                              errors={errors}
                              setErrors={setErrors}
                              nameclass="small"
                              disabled={user.role !== ROLES_USER.DIRECTOR}
                            />
                            <SuperInput
                              state={yearPlan}
                              setState={setBudgetByYear}
                              specificator={yearPlanSpecificator}
                              path={`billing`}
                              errors={errors}
                              setErrors={setErrors}
                              nameclass="small"
                              disabled={user.role !== ROLES_USER.DIRECTOR}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="array column flex-7 minw-prct-60 labeled-block">
                        <div className="row space-between marg-between-r padd-s">
                          {Object.keys(MONTHS).map((month) => (
                            <SuperInput
                              state={budget}
                              label={MONTHS[month]}
                              setState={setBudget}
                              specificator={monthPlanSpecificator}
                              path={`monthsPlan.${month}.selected`}
                              errors={errors}
                              setErrors={setErrors}
                              nameclass={'small wr-4'}
                              salt={month}
                              key={`tick-${month}`}
                              disabled={user.role !== ROLES_USER.DIRECTOR}
                            />
                          ))}
                        </div>
                        <div className="row space-between marg-between-r fill marg-b">
                          {Object.keys(MONTHS).map((month) => (
                            <div className="column wr-4 centered" key={month}>
                              {MONTH_PLAN.map((plan) => (
                                <SuperInput
                                  key={`${plan}-${month}`}
                                  state={budget}
                                  setState={(budget) => {
                                    if (budget.monthsPlan[month].selected) {
                                      if (plan === 'days') setDaysByMonth(budget);
                                      else setBudget(budget);
                                    }
                                  }}
                                  specificator={monthPlanSpecificator}
                                  path={`monthsPlan.${month}.${plan}`}
                                  errors={errors}
                                  setErrors={setErrors}
                                  disabled={
                                    budget.monthsPlan && budget.monthsPlan[month] && !budget.monthsPlan[month].selected
                                  }
                                  className={'small'}
                                  salt={month}
                                />
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="row w-100 marg-between-r space-between wrapper">
                      <div className="minw-25r flex-3">
                        <DoughnutChart
                          data={doughtnutData}
                          middleTitle={yearlyBilling.label}
                          middleDescription={yearlyBilling.description}
                          middleValue={round(yearlyBilling.value, 0)}
                        />
                      </div>
                      <div className="flex-7 wrapper minw-prct-60">
                        <Bar
                          id="histogram"
                          height={70}
                          options={{
                            hover: {
                              onHover: function (_, activeElements) {
                                document.getElementById('histogram').style.cursor =
                                  activeElements.length > 0 ? 'pointer' : 'default';
                              },
                            },
                            onClick: function (event) {
                              const elt = this.chart.getElementAtEvent(event)[0];
                              if (elt != null) {
                                // hide the charges and other incomes panels
                                setShowCharges(false);
                                setShowOtherIncomes(false);
                                // if the previous month has been click
                                // or the month details are not displayed yet
                                if (selectedMonthDetails === elt._index || !showMonthDetails) {
                                  // toggle the show state of the month details panel
                                  setShowMonthDetails(!showMonthDetails);
                                }
                                setSelectedMonthDetails(elt._index);
                              }
                            },
                            scales: {
                              // stack all the datasets
                              // onto the same label
                              xAxes: [
                                {
                                  stacked: true,
                                },
                              ],
                              yAxes: [
                                {
                                  stacked: true,
                                },
                              ],
                            },
                          }}
                          legend={{
                            display: false,
                          }}
                          data={{
                            labels: Object.values(MONTHS),
                            datasets: barchartDatasets,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row marg-between-r centered wrapper">
                    {showOtherIncomes &&
                      budget.otherIncomes &&
                      otherIncomesSpecificator &&
                      otherIncomesSpecificator.fields &&
                      otherIncomesSpecificator.fields.length > 0 && (
                        <div className="labeled-block marg-b">
                          <h3>Détail des autres revenus (ce qui est touché à l’année)</h3>
                          <div className="row">
                            <LabeledContainer
                              values={Object.values(otherIncomesSpecificator.fields)}
                              callback={(othInc, index) => (
                                <SuperInput
                                  key={`${othInc.name}-${index}`}
                                  showLabel={true}
                                  errors={errors}
                                  setErrors={setErrors}
                                  state={budget.otherIncomes}
                                  setState={(data) =>
                                    setBudget({
                                      ...budget,
                                      otherIncomes: {
                                        ...otherIncomesDetails(
                                          associate.user.contribution,
                                          chargedWage,
                                          yearPlan.days,
                                          budget.otherIncomes && budget.otherIncomes.mealTicket
                                        ),
                                        ...budget.otherIncomes,
                                        ...data,
                                      },
                                    })
                                  }
                                  path={`${othInc.name}`}
                                  specificator={otherIncomesSpecificator}
                                  className="row space-between marg-between-r small"
                                  // maximumValue from Simulation.js L.~650 + components/SuperInput.js
                                  maximumValue={getOtherIncomesMaximumValue(othInc.name)}
                                  //mealTicketActivator from Simulation.js L.~671 + components/SuperInput.js
                                  disabled={
                                    getOtherIncomesActivator(othInc.name)
                                    // || user.role !== ROLES_USER.DIRECTOR
                                  }
                                />
                              )}
                            />
                          </div>
                        </div>
                      )}
                    {showCharges && charges && Object.values(charges).length > 0 && (
                      <div className="labeled-block marg-b">
                        <h3>Détail des charges (gestion et taxes)</h3>
                        <div className="row">
                          <LabeledContainer
                            values={Object.values(charges)}
                            callback={(charge, index) => (
                              <Input
                                key={`${charge.label}-${index}`}
                                label={charge.label}
                                value={round(charge.value, 0)}
                                type="number"
                                className="row space-between marg-between-r small"
                                disabled={true}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}
                    {showMonthDetails && (
                      <div className="labeled-block marg-b">
                        <h3>{`Détail du mois de ${monthDetails.label}`}</h3>
                        <div className="column">
                          <div className="input row marg-between-r space-between">
                            <h4 className="input-label flex-1">Chiffre d'affaires</h4>
                            <span>{monthDetails.turnover}</span>
                          </div>
                          <br />
                          <Dropdown title="Revenu mensuel tout compris">
                            <div className="input row marg-between-r space-between">
                              <h4 className="input-label flex-1">Salaire net</h4>
                              <span>{monthDetails.wage}</span>
                            </div>
                            <div className="input row marg-between-r space-between">
                              <h4 className="input-label flex-1">IK</h4>
                              <span>{`+ ${monthDetails.ik}`}</span>
                            </div>
                            <div className="input row marg-between-r space-between">
                              <h4 className="input-label flex-1">Autres revenus du mois</h4>
                              <div className="column">
                                <span>{`+ ${monthDetails.otherIncomes}`}</span>
                                <span className="breaker" />
                              </div>
                            </div>
                          </Dropdown>
                          <div className="row marg-b">
                            <div className="flex-1"></div>
                            <span>{`- ${monthDetails.wageAllIncluded}`}</span>
                          </div>
                          <div className="input row marg-between-r space-between">
                            <h4 className="input-label flex-1">Charges au mois</h4>
                            <div className="column">
                              <span>{`- ${monthDetails.charges}`}</span>
                              <span className="breaker" />
                            </div>
                          </div>
                          <div className="input row marg-between-r space-between">
                            <h4 className="input-label flex-1">Solde mensuel</h4>
                            <span>{`= ${monthDetails.balance}`}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </section>
              ) : (
                <section className="emptyState centered-block">
                  <div>
                    <Lottie className="emptySvg centered-block" animationData={squirrelSleeping} />
                    <h3 className="emptyTitle">C'est un peu vide ici pour l'instant ...</h3>
                  </div>
                  <div className="emptyProposition">
                    {budgetsIdsAccountingYear.length === 0 ? (
                      <div>
                        {user.role === ROLES_USER.DIRECTOR ? (
                          <p>Aucun budget n'est encore enregistré pour cet associé.</p>
                        ) : (
                          <div>
                            <p>Oups, aucun élément n'est encore enrgistré.</p>
                            <p>Prenez contact avec votre directeur pour échanger sur votre budget à venir.</p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <p>Selectionnez un exercice dans la liste pour afficher la période souhaitée.</p>
                    )}
                  </div>
                </section>
              )}
            </form>
            {renderErrors(errorsAdd)}
          </div>
        </div>
      </main>
      {loadingOperation && <div className="card wall loading"></div>}
      {isDeleting && (
        <Dialog
          title={"Suppression d'un budget"}
          disclaimer={`
					Vous êtes sur le point de supprimer le budget de l'exercice
					${budgetsIdsAccountingYear && dateFormat(budgetsIdsAccountingYear[selectedBudget].date, 'dd/mm/yyyy')}
				`}
          success={async () => {
            await deleteBudget();
          }}
          cancel={() => {
            setIsDeleting(false);
          }}
        ></Dialog>
      )}
      {isAdding && (
        <Dialog
          title={"Ajout d'un budget"}
          disclaimer={
            <>
              <p>Vous allez créer un budget pour un nouvel exercice comptable.</p>
              <p>1 budget = 1 exercice.</p>
            </>
          }
          successLabel={'Continuer'}
          success={async () => {
            await addBudget();
          }}
          cancel={() => {
            setIsAdding(false);
          }}
        ></Dialog>
      )}
    </div>
  );
};
