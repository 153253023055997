import React, { useEffect, useState } from 'react';
import './ValidationRules.scss';

export const ValidationRules = ({ rules, password, checkValidity }) => {
  const [rulesToVerify, setRulesToVerify] = useState(rules);

  useEffect(() => {
    if (password) {
      setRulesToVerify((prevState) => {
        const newState = prevState.map((prev) => {
          return {
            ...prev,
            isValid: password.match(prev.validator),
          };
        });
        checkValidity(newState.every((rule) => rule.isValid));
        return newState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  const renderRules = () => {
    return rulesToVerify.map((rule, key) => {
      return (
        <li key={key} className={'rule-li'}>
          <div className={`rule-marker ${password.length === 0 ? 'none' : rule.isValid ? 'success' : 'danger'}`} />
          <div className={'rule-description'}>{rule.descriptionLabel}</div>
        </li>
      );
    });
  };

  return (
    <div>
      <ul>{renderRules()}</ul>
    </div>
  );
};

ValidationRules.defaultProps = {
  password: 'Eden123',
};
