// Query
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useContext, useState } from 'react';
import Cookies from 'universal-cookie';
import { ReactComponent as DGLogo } from '../../assets/graphics/logo.svg';
// Componants
import HistoryLink from '../../components/HistoryLink';
import Input from '../../components/Input';
import InputPassword from '../../components/InputPassword';
// Constants
import { FORM_MINLENGTH } from '../../constants';
import { LOGIN_USER } from '../../graphql/mutations/login';
import { GET_USER_EMAIL } from '../../graphql/queries/users';
// Providers
import { AuthContext } from '../../providers/AuthProvider';
import { SpecificatorsContext } from '../../providers/SpecificatorsProvider/specificatorsProvider';
// Utils
import { errorFind, hasErrors, onError, renderErrors } from '../../utils/errorHandler';
// Hooks
import { useForm } from '../../utils/hooks';
// SCSS
import './Login.scss';

export const Login = ({ history }) => {
  const authContext = useContext(AuthContext);
  const {
    specificators: { email: emailSpecificator, password: passwordSpecificator },
    loading: loadingSpecificators,
    errors: errorsSpecificators,
  } = useContext(SpecificatorsContext);

  const cookies = new Cookies();

  const [errors, setErrors] = useState(errorsSpecificators);

  const { onChange, values } = useForm(null, {
    email: cookies.get('email') || '',
    password: '',
  });

  const [loginUser, { loading: loadingUser }] = useMutation(LOGIN_USER, {
    // specify the variables that the mutation requires
    variables: values,
    onCompleted: async ({ login: userData }) => {
      // set the token of the user to the local storage
      await authContext.login(userData);
      // set a cookie to remember the email
      cookies.set('email', values.email, { path: '/' });
    },
    onError: (err) => onError(setErrors, err),
  });

  const [getUserEmail, { error: errorUserNotFound }] = useLazyQuery(GET_USER_EMAIL);

  const onBlur = () => {
    //onBlurError(event, values, specificator, errors, setErrors);
    getUserEmail({ variables: { email: values.email } });
  };

  // mark the inputs related to the specified error
  const errorEmail = errorFind(errors, 'email');

  const errorPassword = errorFind(errors, 'password');

  const loading = loadingUser || loadingSpecificators;

  const disableSubmit =
    loading ||
    hasErrors({ email: values.email }, emailSpecificator) ||
    hasErrors({ password: values.password }, passwordSpecificator);

  const onSubmit = (event) => {
    event.preventDefault();
    if (!disableSubmit) loginUser();
  };

  return (
    <div id="login" className="centered-block" data-testid="Login">
      <header>
        <DGLogo id="logo" />
      </header>
      <div className="form-container column">
        <div className="centered-block label">
          <h2>Votre espace personnel</h2>
        </div>
        <form
          className={'login-form ' + (loading ? 'loading' : '')}
          onSubmit={loading ? () => {} : onSubmit}
          noValidate
          data-testid="form"
        >
          <div className="column centered">
            <Input
              data-testid="input-email"
              type="text"
              label="Email"
              name="email"
              autoComplete="email"
              placeholder="email"
              onChange={onChange}
              value={values.email}
              error={errorEmail}
              minLength={FORM_MINLENGTH}
              disabled={loading}
              onBlur={(e) => onBlur(e, emailSpecificator)}
            />
            {errorUserNotFound && (
              <p style={{ fontSize: 11, color: 'red' }}>
                {errorUserNotFound.graphQLErrors[0].extensions.exception.errors}
              </p>
            )}
            <InputPassword
              data-testid="input-password"
              label="Mot de passe"
              name="password"
              autoComplete="password"
              minLength={FORM_MINLENGTH}
              placeholder="••••••••"
              onChange={onChange}
              value={values.password}
              error={errorPassword}
              disabled={loading}
              onBlur={(e) => onBlur(e, passwordSpecificator)}
            />
            <Input data-testid="input-submit" value="Login" type="submit" disabled={disableSubmit} />
          </div>
        </form>
        <div className="centered-block">
          <HistoryLink
            to="/recovery"
            state={{
              email: values.email,
            }}
          >
            {'Mot de passe oublié'}
          </HistoryLink>
        </div>
        {!loading && renderErrors(errors.graphql)}
        {!loading && renderErrors(errors.network)}
      </div>
    </div>
  );
};
