import { useQuery } from '@apollo/react-hooks';
import React, { createContext, useState } from 'react';

import {
  GET_SPECIFICATOR_ADDRESS,
  GET_SPECIFICATOR_CHANGE_PASSWORD,
  GET_SPECIFICATOR_COMPUTATIONS_PARAMETERS,
  GET_SPECIFICATOR_EMAIL,
  GET_SPECIFICATOR_PASSWORD,
  GET_SPECIFICATOR_USER,
  GET_SPECIFICATORS_SIMULATION,
} from '../../graphql/queries/specificators';
import { onError } from '../../utils/errorHandler';

export const SpecificatorsContext = createContext();

export const SpecificatorsProvider = ({ children }) => {
  const [specificators, setSpecificators] = useState({});
  const [loaded, setLoaded] = useState({});
  const [errors, setErrors] = useState({});

  useQuery(GET_SPECIFICATOR_COMPUTATIONS_PARAMETERS, {
    onCompleted: ({ specificatorComputParams }) => {
      setSpecificators({
        ...specificators,
        computParams: specificatorComputParams,
      });
      setLoaded((prevState) => ({ ...prevState, computParams: true }));
    },
    onError: (err) => onError(setErrors, err),
  });

  useQuery(GET_SPECIFICATOR_USER, {
    onCompleted: ({ specificatorUser }) => {
      setSpecificators({
        ...specificators,
        user: specificatorUser,
      });
      setLoaded((prevState) => ({ ...prevState, user: true }));
    },
    onError: (err) => onError(setErrors, err),
  });

  useQuery(GET_SPECIFICATOR_ADDRESS, {
    onCompleted: ({ specificatorAddress }) => {
      setSpecificators({
        ...specificators,
        address: specificatorAddress,
      });
      setLoaded((prevState) => ({ ...prevState, address: true }));
    },
    onError: (err) => onError(setErrors, err),
  });

  useQuery(GET_SPECIFICATOR_EMAIL, {
    onCompleted: ({ specificatorEmail }) => {
      setSpecificators({
        ...specificators,
        email: specificatorEmail,
      });
      setLoaded((prevState) => ({ ...prevState, email: true }));
    },
    onError: (err) => onError(setErrors, err),
  });

  useQuery(GET_SPECIFICATOR_PASSWORD, {
    onCompleted: ({ specificatorPassword }) => {
      setSpecificators({
        ...specificators,
        password: specificatorPassword,
      });
      setLoaded((prevState) => ({ ...prevState, password: true }));
    },
    onError: (err) => onError(setErrors, err),
  });

  useQuery(GET_SPECIFICATOR_CHANGE_PASSWORD, {
    onCompleted: ({ specificatorChangePassword }) => {
      setSpecificators({
        ...specificators,
        changePassword: specificatorChangePassword,
      });
      setLoaded((prevState) => ({
        ...prevState,
        changePassword: true,
      }));
    },
    onError: (err) => onError(setErrors, err),
  });

  useQuery(GET_SPECIFICATORS_SIMULATION, {
    onCompleted: ({ specificatorsSimulation }) => {
      specificatorsSimulation.specificators.forEach((s) => {
        setSpecificators((prevState) => ({
          ...prevState,
          [s.name]: s,
        }));
        setLoaded((prevState) => ({ ...prevState, [s.name]: true }));
      });
    },
    onError: (err) => onError(setErrors, err),
  });

  return (
    <SpecificatorsContext.Provider
      value={{
        specificators,
        loaded,
        errors,
      }}
    >
      {children}
    </SpecificatorsContext.Provider>
  );
};
