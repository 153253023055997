import PropTypes from 'prop-types';
import React from 'react';

export const Button = (props) => {
  const { label, plain, callback, icon: Icon, className, ...rest } = props;

  const buttonClass = plain ? 'plain' : 'hollow';

  const onClick = (event) => {
    event.preventDefault();
    callback();
  };

  return Icon ? (
    <figure className={`cursed icon ${className}`} onClick={onClick}>
      <Icon alt={label} />
    </figure>
  ) : (
    <button {...rest} onClick={onClick} className={`button button-${buttonClass} ${className}`}>
      {label}
    </button>
  );
};

Button.defaultProps = {
  plain: 0,
  callback: () => {},
};

Button.propTypes = {
  label: PropTypes.string,
  callback: PropTypes.func,
};
