import React from 'react';

import { Button } from '../Button/Button';

export const Dialog = (props) => {
  const {
    icon: Icon,
    success,
    children,
    successLabel = 'Oui',
    cancelLabel = 'Annuler',
    cancel,
    title,
    disclaimer,
  } = props;

  const successCall = () => {
    if (success) success();
  };
  const cancelCall = () => {
    if (cancel) cancel();
  };

  return (
    <div className="card wall">
      <div className="popup block column middle">
        {!Icon ? null : <Icon />}
        <div className="infos">
          <h2>{title}</h2>
          {disclaimer && <>{disclaimer}</>}
        </div>
        <div className="column">{children}</div>
        <div className="row marg-between-r">
          <Button label={successLabel} callback={successCall} />
          <Button label={cancelLabel} plain={true} callback={cancelCall} />
        </div>
      </div>
    </div>
  );
};
