import { gql } from 'apollo-boost';

const FIELDS = `fields {
	name
	label
	type
	required
	ageMin
	min
	max
	step
	length
	sameAs
	possibleValues
	regexs {
		value
		message
	}
	defaultValue {
		float
		int
		string
		boolean
	}
	disabled
	description
	percentage
}
`;

export const GET_SPECIFICATORS_SIMULATION = gql`
	{
		specificatorsSimulation {
    		specificators {
				name
				${FIELDS}
			}
		}
	}
`;

export const GET_SPECIFICATOR_COMPUTATIONS_PARAMETERS = gql`
	{
		specificatorComputParams {
			${FIELDS}
		}
	}
`;

export const GET_SPECIFICATOR_USER = gql`
	{
		specificatorUser {
			${FIELDS}
		}
	}
`;

export const GET_SPECIFICATOR_ADDRESS = gql`
	{
		specificatorAddress {
			${FIELDS}
		}
	}
`;

export const GET_SPECIFICATOR_PASSWORD = gql`
	{
		specificatorPassword {
			${FIELDS}
		}
	}
`;

export const GET_SPECIFICATOR_CHANGE_PASSWORD = gql`
	{
		specificatorChangePassword {
			${FIELDS}
		}
	}
`;

export const GET_SPECIFICATOR_EMAIL = gql`
	{
		specificatorEmail {
			name
			${FIELDS}
		}
	}
`;
