import React from 'react';
import HistoryLink from '../../components/HistoryLink/HistoryLink';

export const Error404 = () => {
  return (
    <div className="centered-block" data-testid="Error404">
      <div className="centered-block">
        <h1>Error 404</h1>
        <p>Oups, something went wrong</p>
        <HistoryLink className="centered">Go back</HistoryLink>
      </div>
    </div>
  );
};
