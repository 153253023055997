import React from 'react';

import { errorFind } from '../../utils/errorHandler';
import { formatInput, inputType } from '../../utils/specificators';
import { makePath } from '../../utils/utils';
import Input from '../Input/Input';

/**
 * Uses the Input, a state, its setter, a path, the errors and a specificator
 * to manage the type and in & out value of an input
 * @param {{
		name: String,
		type: String,
		label: String,
		showLabel: Boolean,
		state,
		setState: function,
		path: String,
		specificator: {},
		errors: {},
		setErrors: function,
		className: String,
		disabled: Boolean,
		placeholder: String,
		salt: String,
		maximumValue: Number,
		mealTicketActivator: Boolean,
		showInterval: Boolean
	}} props 
 * @return {Input}
 */
export default function SuperInput(props) {
  const {
    type,
    label,
    showLabel = false,
    state = {},
    setState = () => {},
    path,
    specificator,
    errors,
    className,
    disabled = false,
    placeholder,
    salt,
    maximumValue,
    showInterval,
  } = props;

  const pathArr = path && path.split('.');
  const nameStr = pathArr && pathArr.length > 0 ? pathArr.slice(-1)[0] : '';
  const showLabelProp = !!label || showLabel;

  let newState = JSON.parse(JSON.stringify(state));

  // check that the path is correct until the end
  // deep dive in the path and get leaf's value and its parent (last node above)
  const makePathRes = makePath(newState, pathArr);
  let { parent, value } = makePathRes;

  const spec = specificator && specificator.fields && specificator.fields.find((f) => f.name === nameStr);

  const checkSpec = !!spec;

  const defaultValue =
    checkSpec && spec.defaultValue && spec.defaultValue[spec.type] != null ? spec.defaultValue[spec.type] : '';

  const changeValue = (value) => {
    if (!disabled) {
      if (spec.percentage) value /= 100;
      const formattedValue = formatInput(value, spec);
      parent[nameStr] = formattedValue;
      setState(newState);
    }
  };

  const onChange = (event) => {
    if (checkSpec && spec.type !== 'number') {
      let value = spec.type === 'boolean' ? event.target.checked : event.target.value;
      changeValue(value);
    }
  };
  let displayedValue = value != null ? value : '';

  // const onBlur = (event) => {
  // 	if (checkSpec) {
  // 		onBlurError(event, parent, { fields: [spec] }, errors, setErrors);
  // 		if (spec.type === "number") changeValue(event.target.value);
  // 	}
  // };

  const onFocus = (event) => {
    if (disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (props.onFocus) props.onFocus();
  };

  const error = errorFind(errors, nameStr);

  // we increase the bounderies so the value can reach its true bounderies
  // e.g. : if we do NOT increase the bounderies :
  //		step <- 10 and maximum <- 123
  //			==> the user will only be able to access 120
  // 		if we DO increase the bounderies :
  //		step <- 10 and maximum <- 123
  //			==> the user will only be able to access 130
  //			but it will be maxed out at 123
  // 			by the formatInput function
  let min, max;
  if (checkSpec) {
    min = spec.min != null ? Math.floor(spec.min, spec.step || 1) : null;
    max = maximumValue || (spec.max != null ? Math.ceil(spec.max, spec.step || 1) : null);

    if (spec.percentage) {
      displayedValue *= 100;
      min *= 100;
      max *= 100;
    }

    // if(spec.step) displayedValue = round(roundTo(displayedValue, spec.step))
  }

  return checkSpec ? (
    <Input
      label={showLabelProp ? label || spec.label || '' : ''}
      placeholder={placeholder || spec.label || ''}
      name={nameStr}
      value={displayedValue}
      checked={spec.type !== 'boolean' ? false : value || defaultValue}
      //onBlur={!disabled ? onBlur : null}
      onFocus={onFocus}
      onChange={!disabled ? onChange : null}
      error={error}
      type={type || inputType(spec.type)}
      step={spec.step}
      min={min}
      max={max}
      className={className}
      required={spec.required}
      disabled={disabled || spec.disabled}
      salt={salt}
      description={spec.description}
      percentage={spec.percentage && spec.percentage.toString()}
      showInterval={showInterval}
    />
  ) : (
    <div></div>
  );
}
