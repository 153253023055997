import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import React from 'react';

import { IP } from './config';

export default (props) => {
  const httpLink = createHttpLink({
    uri: IP,
  });

  const authLink = setContext(() => {
    const token = localStorage.getItem('jwtToken');
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const client = new ApolloClient({
    // Provide required constructor fields
    cache: new InMemoryCache({
      addTypename: false,
    }),
    // Provide some optional constructor fields
    name: 'react-web-client',
    link: authLink.concat(httpLink),
    version: '0.1',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
