import { gql } from 'apollo-boost';

const monthPlanFrag = `
    days
    kmDay
    wage
    billing
    selected
`;

export const FRAGMENT_BUDGET = gql`
fragment FragmentBudget on Budget {
    id
    user
    accountingYear
    otherIncomes {
		allowances
		cesu
		equipment
		clientMeal
		otherCharges
		companyCar
        mealTicket
        mealPack
	}
    monthsPlan {
        JANUARY {
            ${monthPlanFrag}
        }
		FEBRUARY {
            ${monthPlanFrag}
        }
		MARCH {
            ${monthPlanFrag}
        }
		APRIL {
            ${monthPlanFrag}
        }
		MAY {
            ${monthPlanFrag}
        }
		JUNE {
            ${monthPlanFrag}
        }
		JULY {
            ${monthPlanFrag}
        }
		AUGUST {
            ${monthPlanFrag}
        }
		SEPTEMBER {
            ${monthPlanFrag}
        }
		OCTOBER {
            ${monthPlanFrag}
        }
		NOVEMBER {
            ${monthPlanFrag}
        }
		DECEMBER {
            ${monthPlanFrag}
        }
    }
    createdAt
    updatedAt
    __typename
}
`;
