import { gql } from 'apollo-boost';

export const GET_FACILITIES = gql`
  {
    facilities {
      id
      name
      address {
        address1
        address2
        postalCode
        city
        state
      }
    }
  }
`;
