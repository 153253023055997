import { getAge, isNumeric } from './utils';

export const initialiserDefaultSpec = (obj, specificator) => {
  let res = { ...obj };
  if (specificator.fields)
    specificator.fields.forEach((f) => {
      if (f.defaultValue && f.defaultValue[f.type] != null) res[f.name] = f.defaultValue[f.type];
    });
  return res;
};

export const inputType = (type) => {
  switch (type) {
    case 'boolean':
      return 'checkbox';
    case 'float':
    case 'int':
      return 'number';
    case 'string':
    default:
      return 'text';
  }
};

/**
 * formats an input using its specifications
 * @param {*} value to be formatted
 * @param {*} specField specificator
 */
export const formatInput = (value, specField) => {
  let res;
  if (value == null) return value;
  switch (specField.type) {
    case 'int':
      res = !isNumeric(value) || !Number.isSafeInteger(parseInt(value)) ? 0 : parseInt(value);
      return res;
    case 'float':
      res = !isNumeric(value) ? 0.0 : parseFloat(value);
      return res;
    case 'string':
      return value.toString();
    case 'boolean':
      return value;
    default:
    case 'array':
      return value;
  }
};

/**
 * function to validate a given value as an id (containing 24 chars)
 * @param {string} value
 * @return `true` if the value is a valid id and `false` otherwise
 */
const isId = (value) => {
  return value.toString().match(/^[0-9a-fA-F]{24}$/);
};

/**
 * validates a specified field and its value according to a specified specificator
 * @param {*} obj Object containing the specified field
 * @param {*} fieldName Name of the field of `obj` to be validated
 * @param {*} specificator Object describing how to validate a set of fields
 * @return {{label: String, errors : [String]}} Object containing the label of the given `fieldName`
 * and its list of errors if it has any
 */
export const validateField = (obj, fieldName, specificator) => {
  const field = specificator.fields.find((f) => f.name === fieldName);
  const value = obj && obj[fieldName];

  let res = {
    label: 'Le champ',
    errors: [],
  };

  // if the field is not recognised by the specificator & its value is not defined
  // => an error is thrown
  // if its value is not null, it may have to be validated by another specificator
  // => we skip it
  // <?> needed for the objects that need to be validated by more than one specificator </?>
  if (!field && value == null) {
    res.errors.push("n'est pas reconnu");
    return res;
  }

  if (field) res.label = field.label;

  // if the field is required but its value is either empty or undefined
  if (field && field.required && (value == null || value.toString().length < 1)) {
    if (field.type === 'boolean') res.errors.push(`doit être coché`);
    else res.errors.push(`doit être renseigné`);
    return res;
  }

  if (value != null) {
    const sameField = specificator.fields.find((f) => f.name === field.sameAs);
    if (sameField != null && value !== obj[field.sameAs]) {
      res.errors.push(`et ${sameField.label.toLowerCase()} doivent être identiques`);
      return res;
    }
    switch (field.type) {
      case 'id':
        if (!isId(value)) res.errors.push(`n'a pas une valeur reconnue`);
        break;
      case 'string':
        if (field.possibleValues != null && !field.possibleValues.find((v) => v === value)) {
          res.errors.push(`n'a pas une valeur reconnue`);
        } else {
          if (field.size && field.size !== value.toString().length) {
            res.errors.push(`doit être de longueur ${field.size}`);
          }
          if (field.regexs && field.regexs.length > 0) {
            const regexErrs = [];
            field.regexs.forEach((regex) => {
              if (!new RegExp(regex.value).test(value)) regexErrs.push(regex.message);
            });
            if (regexErrs.length > 0) res.errors = res.errors.concat(regexErrs);
          }
        }
        break;
      case 'date':
        if (Number.isNaN(new Date(value).getTime())) res.errors.push(`est non valide`);
        else if (field.ageMin != null && field.ageMin > getAge(value))
          res.errors.push(`doit être agé de plus de ${field.ageMin} an(s)`);
        break;
      case 'int':
        if (!isNumeric(value) || !Number.isSafeInteger(parseInt(value))) res.errors.push(`doit être un nombre`);
        else {
          if (field.min != null && parseInt(value) < field.min) res.errors.push(`doit être supérieur à ${field.min}`);
          if (field.max != null && field.max < parseInt(value)) res.errors.push(`doit être inférieur à ${field.max}`);
        }
        break;
      case 'float':
        if (!isNumeric(value)) res.errors.push(`doit être un nombre`);
        else {
          if (field.min != null && parseFloat(value) < (field.percentage ? field.min * 100 : field.min))
            res.errors.push(`doit être supérieur à ${field.min}`);
          if (field.max != null && (field.percentage ? field.max * 100 : field.max) < parseFloat(value))
            res.errors.push(`doit être inférieur à ${field.max}`);
        }
        break;
      default:
        break;
    }
  }
  return res;
};

/**
 * function to validate a given object using a `specificator`
 * @param {*} obj Object to be tested by the given `specificator`
 * @param {*} specificator Object describing how to validate a set of fields
 * @return {{validation : {label: String, errors : [String]}}}
 * Object containing the labels and lists of errors of the fields of the given object
 */
export const validate = (obj, specificator) => {
  let errors = {};
  if (specificator)
    specificator.fields.forEach((field) => {
      let errorsField = validateField(obj, field.name, specificator);
      if (errorsField.errors.length > 0) errors = { ...errors, [field.name]: errorsField };
    });
  return { validation: errors };
};
