import React from 'react';

const List = (props) => {
  const { obj, keys = '' } = props;

  return Object.keys(obj).map((value, k) =>
    typeof obj[value] == 'string' ? (
      <li key={`${keys}-${value}-${k}`}>
        <ul>
          <span>{value} :</span>
          <li>{obj[value]}</li>
        </ul>
      </li>
    ) : (
      <li key={`${keys}-${value}-${k}`}>
        <span>{value} :</span>
        <ul>
          <List obj={obj[value]} keys={`${keys}-${value}-${k}`} />
        </ul>
      </li>
    )
  );
};

export default List;
