import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Input from '../../components/Input';
import { Menu } from '../../components/Menu';
import Select from '../../components/Select';
import { ROLES_USER, STATUSES, VEHICLE_POWERS } from '../../constants/constants';
import { ADD_USER } from '../../graphql/mutations/users';
import { GET_FACILITIES } from '../../graphql/queries/facilities';
import { SpecificatorsContext } from '../../providers/SpecificatorsProvider/specificatorsProvider';
import { errorFind, hasErrors, onBlurError, onError, renderErrors } from '../../utils/errorHandler';

export const AddUser = () => {
  const history = useHistory();

  const {
    specificators: { user: userSpecificator, address: addressSpecificator },
    loaded: { loadedUser: loadedUserSpec, loadedAddress: loadedAddressSpec },
  } = useContext(SpecificatorsContext);

  const [errors, setErrors] = useState({});
  const [errorsFacilities, setErrorsFacilities] = useState({});

  const [facilities, setFacilities] = useState({ ids: [], names: [] });
  const [facility, setFacility] = useState({ value: -1, id: null });

  const [user, setUser] = useState({});

  const { loading: loadingFacilities } = useQuery(GET_FACILITIES, {
    onCompleted: async ({ facilities: data }) => {
      setFacilities({
        ids: data.map((f) => f.id),
        names: data.map((f) => f.name),
      });
    },
    notifyOnNetworkStatusChange: true,
    onError: (err) => onError(setErrorsFacilities, err),
  });

  const [addUser, { loading: loadingAddUser }] = useMutation(ADD_USER, {
    variables: {
      user: {
        ...user,
        vehiclePower: parseInt(user.vehiclePower),
        commissionRate: parseFloat(user.commissionRate) / 100,
      },
    },
    skip: !user,
    onCompleted: () => {
      history.push('/userManagement');
    },
    onError: (err) => onError(setErrors, err),
  });

  const onChange = (event) => {
    switch (event.target.type) {
      case 'checkbox':
        setUser({ ...user, [event.target.name]: event.target.checked });
        break;
      case 'number':
        const value = parseInt(event.target.value) || 0;
        setUser({
          ...user,
          [event.target.name]: isNaN(value) ? 0 : value,
        });
        break;
      default:
        setUser({ ...user, [event.target.name]: event.target.value });
        break;
    }
  };

  const onBlurName = (event) => {
    if (!user.email && user.firstname && user.lastname) {
      const email = `${user.firstname.toLowerCase()}.${user.lastname.toLowerCase()}@digital-associates.fr`;
      setUser({ ...user, email });
    }
  };

  const onChangeAddress = (event) => {
    setUser({
      ...user,
      address: {
        ...user.address,
        [event.target.name]: event.target.value,
      },
    });
  };

  const onChangeAddressInt = (event) => {
    const value = event.target.value;
    setUser({
      ...user,
      address: {
        ...user.address,
        [event.target.name]: isNaN(value) ? '' : value,
      },
    });
  };

  const onSelectChange = (event, setState, values) => {
    const value = event.target.value;
    if (value >= 0 && value < values.ids.length) {
      setState({ value: value, id: values.ids[value] });
      setUser({ ...user, [event.target.name]: values.ids[value] });
    } else {
      setState({ value: -1, id: null });
      setUser({ ...user, [event.target.name]: null });
    }
  };

  const onBlur = (event) => {
    onBlurError(event, user, userSpecificator, errors, setErrors);
  };

  const onBlurAddress = (event) => {
    onBlurError(event, user.address, addressSpecificator, errors, setErrors);
  };

  const loading = loadedUserSpec || loadedAddressSpec || loadingAddUser;

  const disableSubmit = loading || hasErrors(user, userSpecificator) || hasErrors(user.address, addressSpecificator);

  const onSubmit = (event) => {
    event.preventDefault();
    if (!disableSubmit) addUser();
  };

  return (
    <div className="row whole-height fill">
      <Menu />
      <main className="block column fill">
        <h1 className="marg-b">Création d'un collaborateur</h1>
        <div className="form-container">
          <form
            data-testid="form"
            className="form"
            loading={loading.toString()}
            onSubmit={loading ? () => {} : onSubmit}
          >
            <div className="column">
              <div className="row">
                <div className="column">
                  <Select
                    label="Structure"
                    defaultOption="Choisir une structure"
                    onChange={(e) => onSelectChange(e, setFacility, facilities)}
                    loading={loadingFacilities}
                    values={facilities.names}
                    value={facility.value}
                    name="facility"
                    error={errorFind(errors, 'facility')}
                    onBlur={onBlur}
                  />
                  <Input
                    label="Nom"
                    name="lastname"
                    value={user.lastname}
                    onChange={onChange}
                    onBlur={(e) => {
                      onBlurName(e);
                      onBlur(e);
                    }}
                    error={errorFind(errors, 'lastname')}
                  />
                  <Input
                    label="Prénom"
                    name="firstname"
                    value={user.firstname}
                    onChange={onChange}
                    onBlur={(e) => {
                      onBlurName(e);
                      onBlur(e);
                    }}
                    error={errorFind(errors, 'firstname')}
                  />
                </div>
                <div className="column">
                  <Input
                    label="Matricule"
                    className="small"
                    name="designation"
                    value={user.designation || ''}
                    onChange={onChange}
                    error={errorFind(errors, 'designation')}
                    onBlur={onBlur}
                  />
                  <Input
                    label="Date de naissance"
                    type="date"
                    name="dob"
                    value={user.dob ? user.dob : ''}
                    onChange={onChange}
                    error={errorFind(errors, 'dob')}
                    onBlur={onBlur}
                  />
                </div>
              </div>
              <div className="labeled-block">
                <h3>Adresse</h3>
                <div>
                  <div className="row">
                    <Input
                      label="Numero de voie et adresse"
                      name="address1"
                      onChange={onChangeAddress}
                      value={user.address ? user.address.address1 : ''}
                      error={errorFind(errors, 'address1')}
                      onBlur={onBlurAddress}
                    />
                    <Input
                      label="Complément d'adresse"
                      name="address2"
                      onChange={onChangeAddress}
                      value={user.address ? user.address.address2 : ''}
                      error={errorFind(errors, 'address2')}
                      onBlur={onBlurAddress}
                    />
                  </div>
                  <div className="row">
                    <Input
                      label="Code Postal"
                      name="postalCode"
                      pattern="[0-9]{5}"
                      onChange={onChangeAddressInt}
                      value={user.address && user.address.postalCode ? user.address.postalCode : ''}
                      error={errorFind(errors, 'postalCode')}
                      onBlur={onBlurAddress}
                    />
                    <Input
                      label="Ville"
                      name="city"
                      onChange={onChangeAddress}
                      value={user.address ? user.address.city : ''}
                      error={errorFind(errors, 'city')}
                      onBlur={onBlurAddress}
                    />
                    <Input
                      label="Pays"
                      name="state"
                      value={user.address ? user.address.state : ''}
                      onChange={onChangeAddress}
                      error={errorFind(errors, 'state')}
                      onBlur={onBlurAddress}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <Input
                  label="Email"
                  name="email"
                  value={user.email || ''}
                  onChange={onChange}
                  error={errorFind(errors, 'email')}
                  onBlur={onBlur}
                />
                <Select
                  label="Rôle"
                  defaultOption="Choisir un rôle"
                  name="role"
                  onChange={onChange}
                  values={ROLES_USER}
                  value={user.role}
                  error={errorFind(errors, 'role')}
                  onBlur={onBlur}
                />
                <Select
                  label="Statut"
                  defaultOption="Choisir un statut"
                  name="status"
                  onChange={onChange}
                  values={STATUSES}
                  value={user.status}
                  error={errorFind(errors, 'status')}
                  onBlur={onBlur}
                />
              </div>
              <div className="row">
                <Input
                  label="Véhicule"
                  name="companyCar"
                  type="checkbox"
                  checked={user.companyCar || ''}
                  onClick={onChange}
                  error={errorFind(errors, 'companyCar')}
                  onBlur={onBlur}
                />
                <Select
                  label="Puissance du véhicule"
                  defaultOption="Choisir une puissance"
                  name="vehiclePower"
                  onChange={onChange}
                  values={VEHICLE_POWERS}
                  value={user.vehiclePower}
                  error={errorFind(errors, 'vehiclePower')}
                  onBlur={onBlur}
                />
                <Input
                  label="Ticket restaurants"
                  name="mealTicket"
                  type="checkbox"
                  checked={user.mealTicket || false}
                  onClick={onChange}
                  error={errorFind(errors, 'mealTicket')}
                  onBlur={onBlur}
                />
              </div>
              <div className="row">
                <Input
                  label="Participation"
                  type="number"
                  className="small"
                  min="0"
                  max="2000"
                  name="contribution"
                  value={user.contribution || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errorFind(errors, 'contribution')}
                />
                <Input
                  label="Taux de commission de gérance"
                  type="number"
                  className="small"
                  percentage="true"
                  min="0"
                  max="100"
                  name="commissionRate"
                  value={user.commissionRate != null ? user.commissionRate.toString() : ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errorFind(errors, 'commissionRate')}
                />
              </div>
              <Input className="centered marg-b" value="Créer" type="submit" disabled={disableSubmit} />
            </div>
          </form>
          {!loadingFacilities && errorsFacilities && renderErrors(errorsFacilities.graphql)}
          {!loadingFacilities && errorsFacilities && renderErrors(errorsFacilities.network)}
        </div>
      </main>
    </div>
  );
};
