import jwt from 'jsonwebtoken';
import React, { useState } from 'react';
import { createContext } from 'react';

let initialUser = null;

if (localStorage.getItem('jwtToken')) {
  const decodedToken = jwt.decode(localStorage.getItem('jwtToken'));

  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem('jwtToken');
  } else {
    initialUser = decodedToken;
  }
}

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(initialUser);

  const login = (userData) => {
    localStorage.setItem('jwtToken', userData.token);
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem('jwtToken');
    setUser(null);
  };

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>;
};
