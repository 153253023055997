import React from 'react';

const LabeledContainer = (props) => {
  const { values, callback, className = '', elementsPerColumn = 4 } = props;

  let columns = [values.length % elementsPerColumn];
  let iColumns = 0;
  columns[iColumns] = [];
  values.forEach((c, i) => {
    const elt = callback(c, iColumns + i);
    if (i !== 0 && i % elementsPerColumn === 0) {
      // we change the column
      iColumns++;
      // and add a column
      columns[iColumns] = [];
    }
    columns[iColumns].push(elt);
  });
  return (
    <div className={`row centered marg-between-r marg-between-b-otherInc ${className}`}>
      {columns.map((column, i) => (
        <div key={`${i}`} className="column marg-between-b">
          {column}
        </div>
      ))}
    </div>
  );
};
export default LabeledContainer;
