import { useState } from 'react';

export const useForm = (callback, initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    callback();
  };

  return {
    onChange,
    onSubmit,
    values,
  };
};

/**
 * basic hook incorporating a flag to check if the values have been modified
 * @param {{}} initialState
 */
export const useFlaggedState = (initialState = {}) => {
  const [values, setValues] = useState(initialState);
  const [flag, setFlag] = useState(null);

  /**
   * @param {{}} data new values of the state
   * @param {boolean} active let us choose not to update the flag
   */
  const updateValues = (data, active = true) => {
    setValues(data);
    if (active) setFlag(new Date().getTime().toString());
  };

  return [values, updateValues, flag, setFlag];
};
