import React, { useState } from 'react';

/**
 *
 * @param {{
 *  title : String,
 *  showed : Boolean,
 *  children : any,
 *  nothingText : String
 * }} args
 */
const Dropdown = ({ title, showed, children, nothingText = 'Rien à afficher' }) => {
  const [hidden, setHidden] = useState(showed == null);

  const toggleContainer = (event) => {
    setHidden(!hidden);
  };

  return (
    <div className="dropdown column">
      <div onClick={toggleContainer} className={`input cursed padd-r ${!hidden && 'showed'}`}>
        {title && <h5 className="input-label flex-1 padd-r">{title}</h5>}
      </div>
      {!hidden && <div className="column marg-t">{children || nothingText}</div>}
    </div>
  );
};

export default Dropdown;
