import React from 'react';

import List from '../components/List/List';
import { validate, validateField } from './specificators';

export const onBlurError = (event, obj, specificator, errors, setErrors) => {
  const fieldName = event.target.name;
  const errorsField = specificator && validateField(obj, fieldName, specificator);
  setErrors({
    ...errors,
    validation: {
      ...(errors && errors.validation),
      [fieldName]: errorsField,
    },
  });
};

export const hasErrors = (obj, specificator) => {
  return specificator && Object.values(validate(obj, specificator).validation).length > 0;
};

export const isRequired = (fieldName, specificator) => {
  return (
    specificator && specificator.fields && specificator.fields[fieldName] && specificator.fields[fieldName].required
  );
};

export const errorFind = (errors, prop) =>
  errors != null &&
  errors.validation != null &&
  errors.validation[prop] != null &&
  errors.validation[prop].errors != null &&
  errors.validation[prop].errors.length > 0
    ? errors.validation[prop]
    : null;

export const onError = (setErrors, errors) => {
  const { graphQLErrors, networkError, message } = errors;
  if (graphQLErrors && graphQLErrors.length > 0) {
    if (graphQLErrors.validation) {
      setErrors({
        ...errors,
        validation: graphQLErrors[0].extensions.exception.errors,
      });
      delete graphQLErrors.validation;
    }
    setErrors({
      ...errors,
      graphql: graphQLErrors[0].extensions.exception.errors || graphQLErrors[0].message,
    });
  } else if (networkError) {
    setErrors({
      errors: {
        ...errors,
        network: {
          [networkError.name]: networkError.message,
        },
      },
    });
  } else if (message) {
    setErrors({
      errors: {
        ...errors,
        network: {
          0: message,
        },
      },
    });
  }
};

export const renderError = (error) => (
  <div className="msg msg-error" data-testid="error-msg">
    {!error ? null : (
      <>
        <span>{error.label}</span>
        {error.errors != null && error.errors.length > 0 && (
          <ul>
            {Object.values(error.errors).map((v) => (
              <li key={`${Math.random()}-${v}`}>{v}</li>
            ))}
          </ul>
        )}
      </>
    )}
  </div>
);

export const renderBubbleError = (error) => {
  return (
    <div className="bubble-ctn">
      <span className="bubble bubble-error">!</span>
      {renderError(error)}
    </div>
  );
};

// const renderObject = (obj, k) => {
// 	return Object.keys(obj).map((value, key) =>
// 		typeof obj[value] == "string" ? (
// 			<li key={`${k}-${value}-${key}`}>
// 				<ul>
// 					<span>{value} :</span>
// 					<li>{obj[value]}</li>
// 				</ul>
// 			</li>
// 		) : (
// 			<li key={`${k}-${value}-${key}`}>
// 				<span>{value} :</span>
// 				<ul>{renderObject(obj[value], `${k}-${value}-${key}`)}</ul>
// 			</li>
// 		)
// 	);
// };

export const renderErrors = (errors) =>
  !errors || Object.values(errors).length < 1 ? null : (
    <div className="notif" data-testid="error-notif">
      <ul type="0">
        {typeof errors == 'string' ? <li>{errors}</li> : <List obj={errors} key={''} />}
        {/* (
					renderObject()
					Object.keys(errors).map((value, key) =>
						typeof errors[value] == "string" ? (
							<li key={`${value}-${key}`}>
								<ul>
									<span>{value} :</span>
									<li>{errors[value]}</li>
								</ul>
							</li>
						) : (
							<li key={`${value}-${key}`}>
								<span>{value} :</span>
								<ul>
									{Object.values(errors[value]).map((v) => (
										<li key={`${key}-${v}`}>{v}</li>
									))}
								</ul>
							</li>
						)
					)
				)} */}
      </ul>
    </div>
  );
