import React from 'react';
import { render } from 'react-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApolloProvider from './ApolloProvider';
import './assets/css/index.scss';
import { AuthProvider } from './providers/AuthProvider';
import { SpecificatorsProvider } from './providers/SpecificatorsProvider/specificatorsProvider';
import Router from './router';
import * as serviceWorker from './serviceWorker';

const renderApp = () =>
  render(
    <React.StrictMode>
      <ApolloProvider>
        <AuthProvider>
          <SpecificatorsProvider>
            <Router />
            <ToastContainer />
          </SpecificatorsProvider>
        </AuthProvider>
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
