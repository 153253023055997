import { gql } from 'apollo-boost';

import { FRAGMENT_COMPUT_PARAMS } from '../fragments/computationsParameters';

export const GET_YEARS_COMPUT_PARAMS = gql`
  query yearsOfComputParams($facility: ID!) {
    yearsOfComputParams(facility: $facility)
  }
`;

export const GET_ALL_COMPUT_PARAMS = gql`
  query allComputParams($facility: ID!) {
    allComputParams(facility: $facility) {
      ...FragmentComputationsParameters
    }
  }
  ${FRAGMENT_COMPUT_PARAMS}
`;

export const GET_COMPUT_PARAMS = gql`
  query computParams($facility: ID!, $year: Int!) {
    computParams(year: $year, facility: $facility) {
      ...FragmentComputationsParameters
    }
  }
  ${FRAGMENT_COMPUT_PARAMS}
`;
