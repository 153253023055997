import { gql } from 'apollo-boost';

import { FRAGMENT_USER } from '../fragments/user';

export const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...FragmentUser
    }
  }
  ${FRAGMENT_USER}
`;

export const GET_USER_EMAIL = gql`
  query userEmail($email: String) {
    userEmail(email: $email) {
      ...FragmentUser
    }
  }
  ${FRAGMENT_USER}
`;

export const GET_NB_USERS_FACILITY = gql`
  query nbUsersFacility($facilityId: ID!) {
    nbUsersFacility(facilityId: $facilityId)
  }
`;

export const GET_ASSOCIATES_NAMES = gql`
  query associatesNames($facilityId: ID!, $userId: ID!) {
    associatesNames(facilityId: $facilityId, userId: $userId) {
      firstname
      lastname
      id
    }
  }
`;

export const GET_USERS_OF_FACILITY = gql`
  query usersOfFacility($id: ID, $query: String) {
    usersOfFacility(id: $id, query: $query) {
      ...FragmentUser
    }
  }
  ${FRAGMENT_USER}
`;

export const GET_USERS = gql`
  {
    users {
      ...FragmentUser
    }
  }
  ${FRAGMENT_USER}
`;
