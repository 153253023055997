import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthContext } from '../../providers/AuthProvider/authProvider';
import { roleHasAccessTo } from '../../utils/utils';

export const RoleRoute = (props) => {
  const { component: Component, exact, ref, sensitive, strict, role } = props;
  const routeParams = { exact, ref, sensitive, strict };
  const { user } = useContext(AuthContext);

  const route404 = <Redirect to={{ pathname: '/404', state: { from: props.location } }} />;
  const routeRole = <Route {...routeParams} {...props} component={Component} />;

  let res;
  if (!user) res = <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
  else if (roleHasAccessTo(user.role, role)) res = routeRole;
  else res = route404;

  return res;
};
