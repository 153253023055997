// BDD
import { useMutation } from '@apollo/react-hooks';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
// Components
import HistoryLink from '../../components/HistoryLink';
import Input from '../../components/Input';
// Constants
import { FORM_MINLENGTH } from '../../constants/constants';
import { FORGOT_PASSWORD } from '../../graphql/mutations/login';
import { SpecificatorsContext } from '../../providers/SpecificatorsProvider/specificatorsProvider';
// Utils
import { errorFind, onError, renderErrors } from '../../utils/errorHandler';

export const ForgotPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const { loading: loadingSpecificators } = useContext(SpecificatorsContext);

  useEffect(() => {
    if (history.location.state && history.location.state.email) {
      setEmail(history.location.state.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  //const getEmail = history.location.state;

  const sendEmail = () => {
    resetPassword({ variables: { email } });
  };

  // Call NodeMailer and manage the security code and redirect to the next view
  const [resetPassword, { loading: loadingForgotPassword }] = useMutation(FORGOT_PASSWORD, {
    onError: (err) => onError(setErrors, err),
    onCompleted: (data) => {
      // data ===> codeToVerify from back end
      // redirection to view ResetPassword (with history, codeToVerify in parameter)
      history.push({
        pathname: '/resetPassword',
        state: { detail: data.forgotPassword, email },
      });
    },
  });

  // mark the inputs related to the specified error
  const errorEmail = errorFind(errors, 'email');

  const loading = loadingSpecificators || loadingForgotPassword;

  return (
    <div className="centered-block forgotPassword" data-testid="ForgotPassword">
      <div className="form-container">
        <div className="centered-block label">
          <h2>Mot de passe oublié</h2>
        </div>
        <div className="centered-block">
          <p className="marg-b">Ca arrive aux meilleurs d'entre nous.</p>
        </div>
        <div className="centered-block">
          <p className="marg-b">La procédure se fait via un code qui vous sera envoyé par mail.</p>
        </div>
        <form noValidate data-testid="form">
          <div className="column">
            <Input
              className="marg-b"
              id="email"
              data-testid="input-email"
              type="text"
              label="Votre email"
              name="email"
              autoComplete="email"
              onChange={onChange}
              value={email}
              error={errorEmail}
              minLength={FORM_MINLENGTH}
              disabled={loading}
            />
          </div>
          {!loading && errors && renderErrors(errors.graphql)}
          {!loading && errors && renderErrors(errors.network)}
        </form>
        <div className="centered-block">
          <p className="centered-block marg-b">Trois cliques et le tour est joué !</p>
        </div>
        <div className="centered-block marg-b">
          <Button data-testid="input-submit" label="Suivant" callback={sendEmail} />
        </div>
        <div className="centered-block">
          <HistoryLink to="/login">{'Retourner à la page du login'}</HistoryLink>
        </div>
      </div>
    </div>
  );
};
