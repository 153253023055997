import { useMutation, useQuery } from '@apollo/react-hooks';
import dateformat from 'dateformat';
import React, { useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import Input from '../../components/Input';
import { Menu } from '../../components/Menu';
import Select from '../../components/Select';
import { ROLES_USER, STATUSES, VEHICLE_POWERS } from '../../constants/constants';
import { SET_USER } from '../../graphql/mutations/users';
import { GET_FACILITIES } from '../../graphql/queries/facilities';
import { GET_USER } from '../../graphql/queries/users';
import { SpecificatorsContext } from '../../providers/SpecificatorsProvider/specificatorsProvider';
import { errorFind, hasErrors, onBlurError, onError, renderErrors } from '../../utils/errorHandler';

export const UpdateUser = () => {
  const history = useHistory();
  const { location } = history;
  const userId = location && location.state && location.state.userId;

  const {
    specificators: { user: userSpecificator, address: addressSpecificator },
    loading: loadingSpecificators,
  } = useContext(SpecificatorsContext);

  const [errors, setErrors] = useState({});
  const [errorsFacilities, setErrorsFacilities] = useState({});
  const [errorsGetUser, setErrorsGetUser] = useState({});

  const [facilities, setFacilities] = useState({ ids: [], names: [] });
  const [facility, setFacility] = useState({ value: -1, id: null });
  const [isUpdating, setIsUpdating] = useState(false);

  const [user, setUser] = useState({});

  const { loading: loadingFacilities } = useQuery(GET_FACILITIES, {
    onCompleted: ({ facilities: data }) => {
      setFacilities({
        ids: data.map((f) => f.id),
        names: data.map((f) => f.name),
      });
      if (data) setErrorsFacilities(null);
    },
    onError: (err) => onError(setErrorsFacilities, err),
  });

  const { loading: loadingGetUser } = useQuery(GET_USER, {
    variables: { id: userId },
    skip: !userId || userId.length < 1,
    onCompleted: (getUser) => {
      const data = getUser && getUser.user;
      if (data) {
        delete data.__typename;
        delete data.id;
        delete data.createdAt;
        const dob = dateformat(data.dob, 'yyyy-mm-dd');
        const commissionRate = parseInt(data.commissionRate * 100);
        setUser({ ...data, dob, commissionRate });
        setFacility({
          value: facilities ? Object.values(facilities.ids).findIndex((e) => e === data.facility) : -1,
          id: data.facility || null,
        });
        setErrors(null);
      }
    },
    onError: (err) => onError(setErrorsGetUser, err),
  });

  const [updateUser, { loading: loadingSetUser }] = useMutation(SET_USER, {
    variables: {
      id: userId,
      user: {
        ...user,
        vehiclePower: parseInt(user.vehiclePower),
        commissionRate: parseFloat(user.commissionRate / 100),
      },
    },
    skip: !userId || userId.length < 1 || !user,
    onCompleted: async ({ setUser: data }) => {
      if (data) {
        delete data.__typename;
        delete data.id;
        delete data.createdAt;
        const dob = dateformat(data.dob, 'yyyy-mm-dd');
        const commissionRate = parseInt(data.commissionRate * 100);
        setUser({ ...data, dob, commissionRate });
        setFacility({
          value: facilities ? Object.values(facilities.ids).findIndex((e) => e === data.facility) : -1,
          id: data.facility || null,
        });
        setIsUpdating(false);
        setErrors(null);
      }
    },
    onError: (err) => onError(setErrors, err),
  });

  const onChange = (event) => {
    switch (event.target.type) {
      case 'checkbox':
        setUser({ ...user, [event.target.name]: event.target.checked });
        break;
      case 'number':
        const value = parseInt(event.target.value) || 0;
        setUser({
          ...user,
          [event.target.name]: isNaN(value) ? 0 : value,
        });
        break;
      default:
        setUser({ ...user, [event.target.name]: event.target.value });
        break;
    }
  };

  const onBlurName = (event) => {
    if (!user.email && user.firstname && user.lastname) {
      const email = `${user.firstname.toLowerCase()}.${user.lastname.toLowerCase()}@digital-associates.fr`;
      setUser({ ...user, email });
    }
  };

  const onChangeAddress = (event) => {
    setUser({
      ...user,
      address: {
        ...user.address,
        [event.target.name]: event.target.value,
      },
    });
  };

  const onChangeAddressInt = (event) => {
    const value = event.target.value;
    setUser({
      ...user,
      address: {
        ...user.address,
        [event.target.name]: isNaN(value) ? '' : value,
      },
    });
  };

  const onSelectChange = (event, setState, values) => {
    const value = event.target.value;
    if (value >= 0 && value < values.ids.length) {
      setState({ value: value, id: values.ids[value] });
      setUser({ ...user, [event.target.name]: values.ids[value] });
    } else {
      setState({ value: -1, id: null });
      setUser({ ...user, [event.target.name]: null });
    }
  };

  const onBlur = (event) => {
    onBlurError(event, user, userSpecificator, errors, setErrors);
  };

  const onBlurAddress = (event) => {
    onBlurError(event, user.address, addressSpecificator, errors, setErrors);
  };

  const loading = loadingSpecificators || loadingGetUser || loadingSetUser;

  const disableSubmit =
    loading || !isUpdating || hasErrors(user, userSpecificator) || hasErrors(user.address, addressSpecificator);

  const onSubmit = (event) => {
    event.preventDefault();
    if (!disableSubmit) updateUser();
  };

  // if the userId is not defined
  if (!userId) return <Redirect to={{ pathname: '/manageUsers' }} />;
  // else
  return (
    <div className="row whole-height fill">
      <Menu />
      <main className="block column fill">
        {!isUpdating ? (
          <h1 className="marg-b">Visualisation d'un collaborateur</h1>
        ) : (
          <h1 className="marg-b">Modification d'un collaborateur</h1>
        )}
        {loading ? (
          <div className="fill loading" />
        ) : (
          <div className="form-container">
            <form
              className={'form ' + (loading ? 'loading' : '')}
              loading={loading.toString()}
              onSubmit={loading ? () => {} : onSubmit}
            >
              <div className="column">
                <div className="row">
                  <Button
                    data-testid={'btn-modification'}
                    label={isUpdating ? 'Arréter la modification' : "Modifier l'utilisateur"}
                    callback={() => setIsUpdating(!isUpdating)}
                    className="marg-b centered"
                  />
                </div>
                <div className="row">
                  <Select
                    label="Structure"
                    defaultOption="Choisir une structure"
                    name="facility"
                    error={errorFind(errors, 'facility')}
                    onChange={(e) => onSelectChange(e, setFacility, facilities)}
                    values={facilities.names}
                    value={facility.value}
                    disabled={!isUpdating}
                    onBlur={onBlur}
                  />
                  <Input
                    disabled={!isUpdating}
                    label="Nom"
                    name="lastname"
                    error={errorFind(errors, 'lastname')}
                    value={user.lastname}
                    onChange={onChange}
                    onBlur={(e) => {
                      onBlurName(e);
                      onBlur(e);
                    }}
                  />
                  <Input
                    disabled={!isUpdating}
                    label="Prénom"
                    name="firstname"
                    value={user.firstname}
                    onChange={onChange}
                    onBlur={(e) => {
                      onBlurName(e);
                      onBlur(e);
                    }}
                    error={errorFind(errors, 'firstname')}
                  />
                </div>
                <div className="row">
                  <Input
                    disabled={!isUpdating}
                    label="Matricule"
                    className="small"
                    name="designation"
                    value={user.designation || ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errorFind(errors, 'designation')}
                  />
                  <Input
                    disabled={!isUpdating}
                    label="Date de naissance"
                    type="date"
                    name="dob"
                    value={user.dob || ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errorFind(errors, 'dob')}
                  />
                </div>
                <div className="labeled-block">
                  <h3>Adresse</h3>
                  <div>
                    <div className="row">
                      <Input
                        disabled={!isUpdating}
                        label="Numero de voie et adresse"
                        name="address1"
                        onChange={onChangeAddress}
                        value={user.address && user.address.address1 ? user.address.address1 : ''}
                        onBlur={onBlurAddress}
                        error={errorFind(errors, 'address1')}
                      />
                      <Input
                        disabled={!isUpdating}
                        label="Complément d'adresse"
                        name="address2"
                        onChange={onChangeAddress}
                        value={user.address && user.address.address2 ? user.address.address2 : ''}
                        onBlur={onBlurAddress}
                        error={errorFind(errors, 'address2')}
                      />
                    </div>
                    <div className="row">
                      <Input
                        disabled={!isUpdating}
                        label="Code Postal"
                        name="postalCode"
                        pattern="[0-9]{5}"
                        onChange={onChangeAddressInt}
                        value={user.address && user.address.postalCode ? user.address.postalCode.toString() : ''}
                        onBlur={onBlurAddress}
                        error={errorFind(errors, 'postalCode')}
                      />
                      <Input
                        disabled={!isUpdating}
                        label="Ville"
                        name="city"
                        onChange={onChangeAddress}
                        value={user.address && user.address.city ? user.address.city : ''}
                        onBlur={onBlurAddress}
                        error={errorFind(errors, 'city')}
                      />
                      <Input
                        disabled={!isUpdating}
                        label="Pays"
                        name="state"
                        value={user.address && user.address.state ? user.address.state : ''}
                        onChange={onChangeAddress}
                        onBlur={onBlurAddress}
                        error={errorFind(errors, 'state')}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <Input
                    disabled={!isUpdating}
                    label="Email"
                    name="email"
                    value={user.email || ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errorFind(errors, 'email')}
                  />
                  <Select
                    label="Rôle"
                    defaultOption="Choisir un rôle"
                    name="role"
                    onChange={onChange}
                    values={ROLES_USER}
                    value={user.role}
                    onBlur={onBlur}
                    error={errorFind(errors, 'role')}
                    disabled={!isUpdating}
                  />
                  <Select
                    label="Statut"
                    defaultOption="Choisir un statut"
                    name="status"
                    onChange={onChange}
                    values={STATUSES}
                    value={user.status}
                    onBlur={onBlur}
                    error={errorFind(errors, 'status')}
                    disabled={!isUpdating}
                  />
                </div>
                <div className="row">
                  <Input
                    disabled={!isUpdating}
                    label="Véhicule"
                    name="companyCar"
                    type="checkbox"
                    checked={user.companyCar || ''}
                    onClick={onChange}
                    onBlur={onBlur}
                    error={errorFind(errors, 'companyCar')}
                  />
                  <Select
                    label="Puissance du véhicule"
                    defaultOption="Choisir une puissance"
                    name="vehiclePower"
                    onChange={onChange}
                    values={VEHICLE_POWERS}
                    value={user.vehiclePower}
                    onBlur={onBlur}
                    error={errorFind(errors, 'vehiclePower')}
                    disabled={!isUpdating}
                  />
                  <Input
                    disabled={!isUpdating}
                    label="Ticket restaurants"
                    name="mealTicket"
                    type="checkbox"
                    checked={user.mealTicket || false}
                    onClick={onChange}
                    onBlur={onBlur}
                    error={errorFind(errors, 'mealTicket')}
                  />
                </div>
                <div className="row">
                  <Input
                    disabled={!isUpdating}
                    label="Participation"
                    type="number"
                    className="small"
                    min="0"
                    max="2000"
                    name="contribution"
                    value={user.contribution}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errorFind(errors, 'contribution')}
                  />
                  <Input
                    disabled={!isUpdating}
                    label="Taux de commission de gérance"
                    type="number"
                    className="small"
                    percentage="true"
                    min="0"
                    max="100"
                    name="commissionRate"
                    value={user.commissionRate != null ? user.commissionRate.toString() : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errorFind(errors, 'commissionRate')}
                  />
                </div>
                {!isUpdating ? null : (
                  <Input
                    value="Appliquer les modifications"
                    data-testid="input-submit"
                    className="centered"
                    type="submit"
                    disabled={disableSubmit}
                  />
                )}
              </div>
            </form>
            {!loadingFacilities && errorsFacilities && renderErrors(errorsFacilities.graphql)}
            {!loadingFacilities && errorsFacilities && renderErrors(errorsFacilities.network)}
            {!loadingGetUser && errorsGetUser && renderErrors(errorsGetUser.graphql)}
            {!loadingGetUser && errorsGetUser && renderErrors(errorsGetUser.network)}
          </div>
        )}
      </main>
    </div>
  );
};
