import 'chartjs-plugin-doughnutlabel';
import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { round } from '../../../utils/utils';
import MessageBubble from '../../MessageBubble/MessageBubble';

const DoughnutChart = (props) => {
  const TIME_OUT_BUBBLE = 0;
  const FONT_SIZE_TITLE = '30';
  const FONT_COLOR_TITLE = 'black';

  const { data, middleTitle, middleValue, className = '' } = props;

  const [infosData, setInfosData] = useState();
  const [lastHoveredLegend, setLastHoveredLegend] = useState(new Date().getTime());

  const middleTitleArr =
    (middleTitle &&
      middleTitle.split(/\s/).map((w) => ({
        text: w,
        color: FONT_COLOR_TITLE,
        font: {
          size: FONT_SIZE_TITLE,
        },
      }))) ||
    [];

  function onLeaveBubble() {
    setTimeout(() => {
      if (lastHoveredLegend < new Date().getTime()) {
        setInfosData(null);
        document.body.style.cursor = 'default';
      }
    }, TIME_OUT_BUBBLE);
  }

  return (
    <div className={`row marg-a maxw-25r ${className}`} onMouseLeave={onLeaveBubble}>
      {infosData && (
        <MessageBubble
          onMouseMove={() => {
            setLastHoveredLegend(new Date().getTime());
          }}
          onMouseLeave={() => {
            onLeaveBubble();
          }}
          style={{
            left: infosData.x,
            top: infosData.y,
            transform: 'translate(0, 0)',
          }}
          label={infosData.label}
          value={infosData.value && round(infosData.value)}
          text={infosData.description}
        />
      )}
      <Doughnut
        data={data}
        options={{
          legend: {
            position: 'left',
            align: 'left',
            onHover: function (event, legendItem) {
              setLastHoveredLegend(new Date().getTime());

              const position = event.target.getBoundingClientRect();
              const legendPos = this.chart.legend.legendHitBoxes[legendItem.index];
              const posX = position.left + legendPos.left;
              // const posY =
              // 	position.top + legendPos.top + legendPos.height;
              const posY = position.top + this.chart.height;
              const descriptions = this.chart.data.descriptions;
              setInfosData({
                label: legendItem.text,
                value: this.chart.data.datasets[0].data[legendItem.index],
                description: descriptions[legendItem.index],
                x: posX,
                y: posY,
              });
              event.target.style.cursor = 'pointer';
              // document.body.style.cursor = "pointer";
            },
            onLeave: function (event, _) {
              event.target.style.cursor = 'default';
              // setInfosData(null);
            },
            onClick: function (event, legendItem) {
              let index = legendItem.index;
              let chart = this.chart;
              let i, ilen, meta;
              for (i = 0, ilen = (chart.data.datasets || []).length; i < ilen; ++i) {
                meta = chart.getDatasetMeta(i);
                // toggle visibility of index if exists
                if (meta.data[index]) {
                  // hides the corresponding dataset
                  // meta.data[index].hidden = !meta.data[index]
                  // 	.hidden;

                  // calls the legend click handler if it is defined
                  if (chart.data.legendClickHandlers && chart.data.legendClickHandlers[index]) {
                    chart.data.legendClickHandlers[index]();
                  }
                }
              }
              // updates
              chart.update();
            },
          },
          tooltips: {
            callbacks: {
              title: function (tooltipItem, data) {
                return data['labels'][tooltipItem[0]['index']];
              },
              label: function (tooltipItem, data) {
                return round(data['datasets'][0]['data'][tooltipItem['index']]);
              },
              afterLabel: function (tooltipItem, data) {
                let dataset = data['datasets'][0];
                let percent = Math.round(
                  (dataset['data'][tooltipItem['index']] / (dataset['_meta'][0] && dataset['_meta'][0]['total']) || 0) *
                    100
                );
                return '(' + percent + '%)';
              },
            },
            position: 'average',
            mode: 'nearest',
          },
          cutoutPercentage: 70,
          plugins: {
            doughnutlabel: {
              labels: [
                ...middleTitleArr,
                {
                  text: '',
                  font: {
                    size: '20',
                  },
                },
                {
                  text: middleValue,
                  color: 'black',
                  font: {
                    size: '50',
                  },
                },
              ],
            },
          },
        }}
      />
    </div>
  );
};
export default DoughnutChart;
