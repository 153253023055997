import { gql } from 'apollo-boost';

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      firstname
      lastname
      email
      role
      token
    }
  }
`;
export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($email: String!, $password: String!, $confirmPassword: String!) {
    changePassword(email: $email, newPassword: $password, confirmPassword: $confirmPassword)
  }
`;
