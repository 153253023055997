import { gql } from 'apollo-boost';

import { FRAGMENT_SIMULATION } from '../fragments/simulation';

export const ADD_SIMULATION = gql`
  mutation addSimulation($userId: ID!, $simulation: SimulationInput!) {
    addSimulation(userId: $userId, simulation: $simulation)
  }
`;

export const ADD_SIMULATION_SIMPLE = gql`
  mutation addSimulationSimple($userId: ID!, $name: String!) {
    addSimulationSimple(userId: $userId, name: $name) {
      ...FragmentSimulation
    }
  }
  ${FRAGMENT_SIMULATION}
`;

export const SET_SIMULATION = gql`
  mutation setSimulation($userId: ID!, $id: ID!, $simulation: SimulationInput!) {
    setSimulation(userId: $userId, id: $id, simulation: $simulation) {
      ...FragmentSimulation
    }
  }
  ${FRAGMENT_SIMULATION}
`;

export const REM_SIMULATION = gql`
  mutation remSimulation($userId: ID!, $id: ID!) {
    remSimulation(userId: $userId, id: $id)
  }
`;
