import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { renderBubbleError } from '../../utils/errorHandler';
// Tools
import { Eye, EyeSlash } from '@styled-icons/bootstrap';

export default function Input(props) {
  const { label, name, error } = props;
  const id = props.id || `input-password-${name}`;
  const testId = props['data-testid'] || id;
  const [show, setShow] = useState(false);

  const showHide = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  return (
    <div className={`input input-password`} error={(error != null).toString()}>
      {label != null && (
        <label className="input-label" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="input-ctn" style={{ alignItems: 'center' }}>
        {error ? renderBubbleError(error) : null}
        <input id={id} data-testid={testId} type={show ? 'text' : 'password'} {...props} />
        <div onClick={(e) => showHide(e)} style={{ width: 15, marginLeft: 8 }}>
          {show ? <Eye /> : <EyeSlash />}
        </div>
      </div>
    </div>
  );
}

Input.defaultProps = {
  value: '',
  error: null,
  onChange: () => {},
};

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  'current-password': PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  step: PropTypes.number,
  required: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};
