import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthContext } from '../../providers/AuthProvider';

export const AuthRoute = (props) => {
  const { component: Component, exact, ref, senstitive, strict } = props;
  const routeParams = { exact, ref, senstitive, strict };
  const { user } = useContext(AuthContext);

  return user ? (
    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  ) : (
    <Route {...routeParams} render={(props) => <Component {...props} />} />
  );
};
