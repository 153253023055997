import { gql } from 'apollo-boost';

import { FRAGMENT_BUDGET } from '../fragments/budget';

export const REM_BUDGET = gql`
  mutation remBudget($userId: ID!, $id: ID!) {
    remBudget(userId: $userId, id: $id)
  }
`;

export const SET_BUDGET = gql`
  mutation setBudget($userId: ID!, $id: ID!, $budgetInput: BudgetInput!) {
    setBudget(userId: $userId, id: $id, budgetInput: $budgetInput) {
      ...FragmentBudget
    }
  }
  ${FRAGMENT_BUDGET}
`;

export const ADD_BUDGET = gql`
  mutation addBudget($userId: ID!) {
    addBudget(userId: $userId) {
      ...FragmentBudget
    }
  }
  ${FRAGMENT_BUDGET}
`;

export const MAKE_BUDGET = gql`
  mutation addBudgetFromSimulation($userId: ID!, $simulationId: ID!) {
    addBudgetFromSimulation(userId: $userId, simulationId: $simulationId)
  }
`;
