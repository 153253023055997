export const FORM_MINLENGTH = 8;

// Minimum lenght call for security code for ResetPassword
export const CODE_MINLENGTH = 6;
export const CODE_MAXLENGHT = 6;

export const ROLES_USER = {
  ADMIN: 'ADMIN',
  ASSOCIATE: 'ASSOCIATE',
  DIRECTOR: 'DIRECTOR',
};
export const VEHICLE_POWERS = {
  3: '3 ou moins',
  4: '4',
  5: '5',
  6: '6',
  7: '7 ou plus',
};
export const STATUSES = {
  ACTIVE: 'Actif',
  INACTIVE: 'Inactif',
};

export const MONTHS = {
  JULY: 'Juillet',
  AUGUST: 'Août',
  SEPTEMBER: 'Septembre',
  OCTOBER: 'Octobre',
  NOVEMBER: 'Novembre',
  DECEMBER: 'Décembre',
  JANUARY: 'Janvier',
  FEBRUARY: 'Février',
  MARCH: 'Mars',
  APRIL: 'Avril',
  MAY: 'Mai',
  JUNE: 'Juin',
};

export const MONTH_PLAN = ['days', 'wage', 'kmDay', 'billing'];
