import { ROLES_USER } from '../constants/constants';

/**
 * finds or creates a path through an object
 * deep dives in the object, gets leaf's value and its parent (last node above)
 * @param {{}} obj 
 * @param {[String]} pathArr 
 * @return {{
		parent : {},
		value: *,
		madePath: Boolean,
	}}
 */
export const makePath = (obj, pathArr) => {
  let parent,
    value = obj;
  let madePath =
    pathArr &&
    value &&
    pathArr
      .map((cur, i) => {
        let res = false;
        if (value[cur] == null) {
          value[cur] = i < pathArr.length - 1 ? {} : '';
          res = true;
        }
        parent = value;
        value = value[cur];
        return res;
      })
      .reduce((p, c) => p && c);
  return {
    parent,
    value,
    madePath,
  };
};

/**
 * finds the parent of a key element inside an object
 * @param {{*}} obj
 * @param {String} key
 */
export const findDeepParentOfKey = (obj, key) => {
  let parent = obj;
  let checklist = [obj];
  let found = false;
  let i = 0;
  while (i < checklist.length && !found) {
    const cur = checklist[i];
    const index = Object.keys(cur).findIndex((k) => {
      // if this is an object but not an array, we add it to the checklist
      if (Object.keys(cur[k]).length > 0 && !cur[k].length) {
        checklist.push(cur[k]);
      }
      return k === key;
    });
    if (index >= 0) {
      parent = cur;
      found = true;
    }
    i++;
  }
  if (found) {
    return parent;
  }
  return null;
};

export const roleHasAccessTo = (userRole, role) => {
  switch (userRole) {
    case role:
      return true;
    case ROLES_USER.ADMIN:
      switch (role) {
        case ROLES_USER.ASSOCIATE:
          return true;
        case ROLES_USER.DIRECTOR:
          return true;
        default:
          break;
      }
      break;
    case ROLES_USER.DIRECTOR:
      switch (role) {
        case ROLES_USER.ASSOCIATE:
          return true;
        default:
          break;
      }
      break;
    default:
      break;
  }
  return false;
};

export const toTitleCase = (str) =>
  str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

/**
 * rounds a value to a supplied decimal place
 * @param {Number} x value to be rounded
 * @param {Number} n decimal place to which round the supplied value
 */
export const round = (x, n = 2) => {
  const precision = Math.pow(10, n);
  return Math.round((x + Number.EPSILON) * precision) / precision;
};

/**
 * rounds a value to a supplied value
 * @param {Number} x value to be rounded
 * @param {Number} precision value to which round the supplied value
 */
export const roundTo = (x, n = 0.1) => {
  const precision = 1 / n;
  return Math.round((x + Number.EPSILON) * precision) / precision;
};

/**
 * ceils a value to a supplied number
 * @param {Number} x value to be rounded
 * @param {Number} n number which to ceil the value
 */
export const ceil = (x, n) => {
  return Math.ceil(x / n) * n;
};
/**
 * floors a value to a supplied number
 * @param {Number} x value to be rounded
 * @param {Number} n number which to floor the value
 */
export const floor = (x, n) => {
  return Math.floor(x / n) * n;
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const isInCurrentFiscalYear = (accountingYear) => {
  // accountingYear = Exercice comptable en cours (ex : 2020-2021)
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  let currentExercice;

  if (month < 5) {
    currentExercice = `${year - 1}-${year}`;
  } else {
    currentExercice = `${year}-${year + 1}`;
  }

  //renvoie un boolean
  return currentExercice === accountingYear;
};
