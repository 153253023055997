import { gql } from 'apollo-boost';

export const FRAGMENT_COMPUT_PARAMS = gql`
  fragment FragmentComputationsParameters on ComputationsParameters {
    facility
    year
    mileageScale
    mealPack
    mealTicket
    cfe
    cvae
    rcp
    payrollSoftwareCost
    accountingFirmCost
    legalCost
    occupationalHealth
    eventsCosts
    vocationalTrainingTax
    chargedWageRate
    clientRetrocommission
    interContractsProvisionRate
    ursaffControlRiskRate
    __typename
  }
`;
