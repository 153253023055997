import { BoxArrowRight } from '@styled-icons/bootstrap';
import React, { useContext } from 'react';
import logoPic from '../../assets/graphics/logo.png';
import { ROLES_USER } from '../../constants/constants';
import { AuthContext } from '../../providers/AuthProvider/authProvider';
import { routes } from '../../router';
import { roleHasAccessTo } from '../../utils/utils';
import { Button } from '../Button';
import { MenuItem } from '../MenuItem';
import './Menu.scss';

export const Menu = (props) => {
  const context = useContext(AuthContext);

  const { user, logout } = context;

  const userMenuItems =
    !user || !user.role
      ? []
      : Object.keys(routes).map((keyRoute) => {
          const roleRoutes = routes[keyRoute];
          const role = ROLES_USER[keyRoute] || null;
          return roleRoutes.map(
            (route) =>
              role &&
              route.dom &&
              roleHasAccessTo(user.role, role) && (
                <MenuItem key={`${user.role}-${route.path}`} path={route.path} {...route.dom} />
              )
          );
        });

  return (
    <header id="menu" className="column">
      <MenuItem label="Home" pic={logoPic} />
      <nav className="fill column">
        <ul className="column">{userMenuItems}</ul>
      </nav>
      <Button label="Déconnexion" plain="true" icon={BoxArrowRight} callback={logout} />
    </header>
  );
};

// Menu.defaultProps = {
// }

// Menu.propTypes = {
// }
