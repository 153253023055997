import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useContext, useState } from 'react';
import { Button } from '../../components/Button';
import Input from '../../components/Input';
import { Menu } from '../../components/Menu';
import Select from '../../components/Select';
import SuperInput from '../../components/SuperInput';
import { UPDATE_COMPUT_PARAMS } from '../../graphql/mutations/computationsParameters';
import { GET_COMPUT_PARAMS, GET_YEARS_COMPUT_PARAMS } from '../../graphql/queries/computationsParameters';
import { GET_FACILITIES } from '../../graphql/queries/facilities';
import { SpecificatorsContext } from '../../providers/SpecificatorsProvider/specificatorsProvider';
import { errorFind, hasErrors, onBlurError, onError, renderErrors } from '../../utils/errorHandler';

const ComputationsParameters = () => {
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    specificators: { computParams: computParamsSpecificator },
    loading: loadingSpecificators,
  } = useContext(SpecificatorsContext);

  const [errors, setErrors] = useState(null);

  const NB_YEARS = 100;
  const yearsRange = new Array(NB_YEARS).fill(new Date().getFullYear() + NB_YEARS / 2).map((curr, i) => curr - i);

  const valueYear = (value) => yearsRange[value] || -1;

  const [years, setYears] = useState();
  const [selectedYear, setSelectedYear] = useState(-1);
  const [computParams, setComputParams] = useState({
    year: valueYear(selectedYear),
  });

  const [facilities, setFacilities] = useState({ ids: [], names: [] });
  const [facility, setFacility] = useState({ value: -1, id: null });

  const onChangeYear = async (event) => {
    const value = event.target.value;
    setSelectedYear(value);
    setComputParams({
      facility: facility.id,
      year: valueYear(value),
    });
    refetchComputParams({ facility: facility.id, year: valueYear(value) });
  };

  const changeToCurrentYear = () => {
    const year = new Date().getFullYear();
    setSelectedYear(yearsRange.indexOf(year));
    setComputParams({
      ...computParams,
      year,
    });
  };

  const { refetch: refecthYears } = useQuery(GET_YEARS_COMPUT_PARAMS, {
    variables: { facility: facility.id },
    skip: !facility || facility.value < 0,
    onCompleted: (data) => {
      if (data && data.yearsOfComputParams) {
        setYears(data.yearsOfComputParams);
      }
    },
  });

  const { loading: loadingFacilities } = useQuery(GET_FACILITIES, {
    onCompleted: async ({ facilities: data }) => {
      setFacilities({
        ids: data.map((f) => f.id),
        names: data.map((f) => f.name),
      });
    },
    notifyOnNetworkStatusChange: true,
    onError: (err) => onError(setErrors, err),
  });

  const { loading: loadingComputParams, refetch: refetchComputParams } = useQuery(GET_COMPUT_PARAMS, {
    variables: { facility: facility.id, year: valueYear(selectedYear) },
    skip: !facility.id || !selectedYear,
    onCompleted: (data) => {
      if (data) {
        delete data.computParams.__typename;
        setComputParams({
          ...data.computParams,
        });
        setErrors(null);
        setIsUpdating(false);
      }
    },
    onError: (err) => {
      onError(setErrors, err);
    },
  });

  const [updateParams, { loading: loadingUpdateParams }] = useMutation(UPDATE_COMPUT_PARAMS, {
    variables: {
      computParams: {
        ...computParams,
      },
    },
    skip: !selectedYear,
    onCompleted: ({ setComputParams: data }) => {
      if (data) {
        delete data.__typename;
        setComputParams({
          ...data,
        });
        setIsUpdating(false);
        refecthYears();
      }
    },
    onError: (err) => onError(setErrors, err),
  });

  const onBlur = (event) => {
    onBlurError(event, computParams, computParamsSpecificator, errors, setErrors);
  };

  const isModifying = years != null && years.find((y) => y === computParams.year) != null;

  const loading = loadingSpecificators || loadingComputParams || loadingUpdateParams;

  const checkSubmissible = () => {
    return !loading && isUpdating && !hasErrors(computParams, computParamsSpecificator);
  };
  const submitDisabled = !checkSubmissible();

  const onSubmit = (event) => {
    event.preventDefault();
    if (!submitDisabled) updateParams();
  };

  const onSelectChange = (event, setState, values) => {
    const value = event.target.value;
    if (value >= 0 && value < values.ids.length) {
      setState({ value: value, id: values.ids[value] });
      setComputParams({
        [event.target.name]: values.ids[value],
      });
    } else {
      setState({ value: -1, id: null });
      setComputParams({ [event.target.name]: null });
      setSelectedYear(-1);
    }
  };

  return (
    <div className="row whole-height fill">
      <Menu />
      <main className="block column fill">
        {!isUpdating ? (
          <h1 className="marg-b">Visualisation d'un paramètre de calcul</h1>
        ) : (
          <h1 className="marg-b">Modification d'un paramètre de calcul</h1>
        )}
        <div className="centered-block middle">
          <Select
            label="Structure"
            defaultOption="Choisir une structure"
            onChange={(e) => onSelectChange(e, setFacility, facilities)}
            loading={loadingFacilities}
            values={facilities.names}
            value={facility.value}
            name="facility"
            error={errorFind(errors, 'facility')}
            onBlur={onBlur}
            className="marg-b"
          />
          {facility.value >= 0 && (
            <Select
              label="Année"
              defaultOption={"Choix de l'année"}
              name="year"
              value={selectedYear}
              values={yearsRange}
              onChange={onChangeYear}
              error={errorFind(errors, 'year')}
              onBlur={onBlur}
              className="marg-b"
            />
          )}
          {facility.value >= 0 && yearsRange[selectedYear] !== new Date().getFullYear() && (
            <Button
              plain={true}
              label={"Changer à l'année courante"}
              callback={() => {
                changeToCurrentYear();
              }}
            />
          )}
        </div>
        {Object.values(computParams).length > 0 && valueYear(selectedYear) > 0 && facility.value >= 0 && (
          <div className="form-container">
            <form className={'form ' + (loading ? 'loading' : '')} loading={loading.toString()} onSubmit={onSubmit}>
              <div className="column">
                <div className="row">
                  <Button
                    data-testid={'btn-modification'}
                    label={isUpdating ? 'Arréter la modification' : 'Modifier les paramètres de calcul'}
                    callback={() => setIsUpdating(!isUpdating)}
                    className="marg-b centered"
                  />
                </div>
                <div className="labeled-block">
                  <h3>Calculs mensuels</h3>
                  <div className="row">
                    <SuperInput
                      disabled={!isUpdating}
                      state={computParams}
                      path={'mileageScale'}
                      setErrors={setErrors}
                      setState={setComputParams}
                      errors={errors}
                      specificator={computParamsSpecificator}
                      showLabel={true}
                      className={'small'}
                    />
                    <SuperInput
                      disabled={!isUpdating}
                      state={computParams}
                      path={'mealTicket'}
                      setErrors={setErrors}
                      setState={setComputParams}
                      errors={errors}
                      specificator={computParamsSpecificator}
                      showLabel={true}
                      className={'small'}
                    />
                    <SuperInput
                      disabled={!isUpdating}
                      state={computParams}
                      path={'mealPack'}
                      setErrors={setErrors}
                      setState={setComputParams}
                      errors={errors}
                      specificator={computParamsSpecificator}
                      showLabel={true}
                      className={'small'}
                    />
                  </div>
                </div>
                <div className="labeled-block">
                  <h3>Charges d'entreprise</h3>
                  <div className="column">
                    <div className="row fill">
                      <SuperInput
                        disabled={!isUpdating}
                        state={computParams}
                        path={'cfe'}
                        setErrors={setErrors}
                        setState={setComputParams}
                        errors={errors}
                        specificator={computParamsSpecificator}
                        showLabel={true}
                        className={'small'}
                      />
                      <SuperInput
                        disabled={!isUpdating}
                        state={computParams}
                        path={'cvae'}
                        setErrors={setErrors}
                        setState={setComputParams}
                        errors={errors}
                        specificator={computParamsSpecificator}
                        showLabel={true}
                        className={'small'}
                      />
                      <SuperInput
                        disabled={!isUpdating}
                        state={computParams}
                        path={'rcp'}
                        setErrors={setErrors}
                        setState={setComputParams}
                        errors={errors}
                        specificator={computParamsSpecificator}
                        showLabel={true}
                        className={'small'}
                      />
                    </div>
                    <div className="row fill">
                      <SuperInput
                        disabled={!isUpdating}
                        state={computParams}
                        path={'payrollSoftwareCost'}
                        setErrors={setErrors}
                        setState={setComputParams}
                        errors={errors}
                        specificator={computParamsSpecificator}
                        showLabel={true}
                        className={'small'}
                      />
                      <SuperInput
                        disabled={!isUpdating}
                        state={computParams}
                        path={'accountingFirmCost'}
                        setErrors={setErrors}
                        setState={setComputParams}
                        errors={errors}
                        specificator={computParamsSpecificator}
                        showLabel={true}
                        className={'small'}
                      />
                      <SuperInput
                        disabled={!isUpdating}
                        state={computParams}
                        path={'legalCost'}
                        setErrors={setErrors}
                        setState={setComputParams}
                        errors={errors}
                        specificator={computParamsSpecificator}
                        showLabel={true}
                        className={'small'}
                      />
                    </div>
                    <div className="row fill">
                      <SuperInput
                        disabled={!isUpdating}
                        state={computParams}
                        path={'occupationalHealth'}
                        setErrors={setErrors}
                        setState={setComputParams}
                        errors={errors}
                        specificator={computParamsSpecificator}
                        showLabel={true}
                        className={'small'}
                      />
                      <SuperInput
                        disabled={!isUpdating}
                        state={computParams}
                        path={'eventsCosts'}
                        setErrors={setErrors}
                        setState={setComputParams}
                        errors={errors}
                        specificator={computParamsSpecificator}
                        showLabel={true}
                        className={'small'}
                      />
                      <SuperInput
                        disabled={!isUpdating}
                        state={computParams}
                        path={'vocationalTrainingTax'}
                        setErrors={setErrors}
                        setState={setComputParams}
                        errors={errors}
                        specificator={computParamsSpecificator}
                        showLabel={true}
                        className={'small'}
                      />
                    </div>
                  </div>
                </div>
                <div className="labeled-block">
                  <h3>Charges annuelles individuelles</h3>
                  <div className="column">
                    <div className="row fill">
                      <SuperInput
                        disabled={!isUpdating}
                        state={computParams}
                        path={'chargedWageRate'}
                        setErrors={setErrors}
                        setState={setComputParams}
                        errors={errors}
                        specificator={computParamsSpecificator}
                        showLabel={true}
                        className={'small'}
                      />
                      <SuperInput
                        disabled={!isUpdating}
                        state={computParams}
                        path={'clientRetrocommission'}
                        setErrors={setErrors}
                        setState={setComputParams}
                        errors={errors}
                        specificator={computParamsSpecificator}
                        showLabel={true}
                        className={'small'}
                      />
                    </div>
                    <div className="row fill">
                      <SuperInput
                        disabled={!isUpdating}
                        state={computParams}
                        path={'interContractsProvisionRate'}
                        setErrors={setErrors}
                        setState={setComputParams}
                        errors={errors}
                        specificator={computParamsSpecificator}
                        showLabel={true}
                        className={'small'}
                      />
                      <SuperInput
                        disabled={!isUpdating}
                        state={computParams}
                        path={'ursaffControlRiskRate'}
                        setErrors={setErrors}
                        setState={setComputParams}
                        errors={errors}
                        specificator={computParamsSpecificator}
                        showLabel={true}
                        className={'small'}
                      />
                    </div>
                  </div>
                </div>
                <Input
                  data-testid="input-submit"
                  className="centered marg-b"
                  value={isModifying ? 'Modifier' : 'Créer'}
                  type="submit"
                  onMouseEnter={() => {
                    checkSubmissible();
                  }}
                  disabled={submitDisabled}
                  onClick={() => {
                    checkSubmissible();
                  }}
                />
              </div>
            </form>
          </div>
        )}
        {!loading && errors && renderErrors(errors.graphql)}
        {!loading && errors && renderErrors(errors.network)}
      </main>
    </div>
  );
};
export default ComputationsParameters;
