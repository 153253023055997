import { gql } from 'apollo-boost';

import { FRAGMENT_USER } from '../fragments/user';

export const SET_USER = gql`
  mutation setUser($id: ID!, $user: UserInput!) {
    setUser(id: $id, user: $user) {
      ...FragmentUser
    }
  }
  ${FRAGMENT_USER}
`;

export const ADD_USER = gql`
  mutation addUser($user: UserInput!) {
    addUser(user: $user)
  }
`;
