import { gql } from 'apollo-boost';

import { FRAGMENT_COMPUT_PARAMS } from '../fragments/computationsParameters';

export const UPDATE_COMPUT_PARAMS = gql`
  mutation updateComputParams($computParams: ComputationsParametersInput!) {
    updateComputParams(computParams: $computParams) {
      ...FragmentComputationsParameters
    }
  }
  ${FRAGMENT_COMPUT_PARAMS}
`;
