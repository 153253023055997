import { gql } from 'apollo-boost';

import { FRAGMENT_BUDGET } from '../fragments/budget';

export const GET_BUDGET = gql`
  query budgetById($userId: ID!, $id: ID!) {
    budgetById(userId: $userId, id: $id) {
      ...FragmentBudget
    }
  }
  ${FRAGMENT_BUDGET}
`;

export const GET_BUDGETS_IDS_ACCOUNTINGYEAR_USER = gql`
  query budgetsIdsAccountingYear($userId: ID!) {
    budgetsIdsAccountingYear(userId: $userId) {
      id
      accountingYear
    }
  }
`;
