import PropTypes from 'prop-types';
import React from 'react';

import { renderBubbleError } from '../../utils/errorHandler';

/**
 * 
 * @param {{
	type: String,
	label: String,
	name: String,
	error: {},
	className: String,
	pattern: String,
	required: Boolean,
	percentage: String,
	value: *,
	step: Number,
	salt: String,
	description: String,
	rest: {}
	}} props 
 */
export default function Input(props) {
  const {
    type,
    label,
    name,
    error,
    className = '',
    pattern,
    required,
    percentage,
    value,
    step,
    salt,
    description,
    showInterval,
    ...rest
  } = props;
  const id = props.id || `input-${type}-${name || type}-${salt}`;

  return (
    <div className={` ${className} input input-${type}`} error={(error != null).toString()} required={required}>
      {label != null && label !== '' && (
        <label className="input-label clip-text" htmlFor={id}>
          {label}
        </label>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'right',
        }}
      >
        {showInterval === true && (
          <p
            style={{
              paddingRight: 10,
              fontSize: 12,
              color: '#a1a1a6',
              width: 32,
            }}
          >
            {rest.min}
          </p>
        )}
        <label
          className="input-ctn"
          style={{
            flex: 1,
          }}
          percentage={percentage}
        >
          {description && (
            <div className="bubble-ctn">
              <span className="bubble">i</span>
              <div className="msg">
                <p>{description}</p>
              </div>
            </div>
          )}
          {error ? renderBubbleError(error) : null}
          <input
            type={type}
            name={name}
            pattern={pattern}
            id={id}
            step={step}
            value={value}
            required={required}
            percentage={percentage}
            data-testid={id}
            {...rest}
          />
          {type !== 'checkbox' ? null : <span className="checker" />}
        </label>
        {showInterval === true && (
          <p
            style={{
              paddingLeft: 10,
              fontSize: 12,
              color: '#a1a1a6',
              width: 32,
            }}
          >
            {rest.max}
          </p>
        )}
      </div>
    </div>
  );
}

Input.defaultProps = {
  type: 'text',
  value: '',
  className: '',
  onChange: () => {},
  onBlur: () => {},
  error: null,
};

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  'current-password': PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  percentage: PropTypes.string,
};
