import { BarChart, Sliders, Wallet2 } from '@styled-icons/bootstrap';
import dateformat from 'dateformat';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../components/Card';
import { Menu } from '../../components/Menu';
import { AuthContext } from '../../providers/AuthProvider';
import './Home.scss';

export const Home = () => {
  const history = useHistory();
  const context = useContext(AuthContext);

  const formattedDate = dateformat(new Date(), 'dd/mm/yyyy');

  return (
    <div id="home" className="fill">
      <Menu />
      <main className="column fill">
        <div className="block whole-width">
          <div id="welcome" className="fill">
            <h3 className="block">Bonjour {context.user.firstname}</h3>
            <p>Bienvenue sur le tableau de bord, nous sommes le {formattedDate}</p>
          </div>
          <div className="balance block column">
            <div>
              <h4>Solde pour 2020/2021</h4>
              <span>#### €</span>
            </div>
            <div>
              <h4>Solde sur toutes les périodes</h4>
              <span>#### €</span>
            </div>
          </div>
        </div>
        <span className="breaker" />
        <h1 className="block centered">Que souhaitez-vous faire aujourd'hui ? </h1>
        <div id="recent-infos" className="column fill">
          <div className="block wrapper centered middle">
            <Card
              icon={Sliders}
              label="Simulation"
              text={`Dernier budget le 00/00/0000`}
              buttonLabel="Réaliser une simulation"
              buttonCallback={() => history.push('/simulation')}
            />
            <Card
              icon={BarChart}
              label="Budget"
              text={`Dernier budget le 00/00/0000`}
              buttonLabel="Consulter"
              buttonCallback={() => history.push('/budget')}
            />
            <Card icon={Wallet2} label="Situation réelle" text={`À jour au ${formattedDate}`} buttonLabel="Consulter" />
          </div>
        </div>
      </main>
    </div>
  );
};
