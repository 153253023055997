import { gql } from 'apollo-boost';
import { FRAGMENT_SIMULATION } from '../fragments/simulation';

export const GET_SIMULATIONS_NAMES = gql`
  query simulationsNames($userId: ID!) {
    simulationsNames(userId: $userId) {
      id
      name
    }
  }
`;

export const GET_SIMULATION_BY_NAME = gql`
  query simulationByName($userId: ID!, $name: String!) {
    simulationByName(userId: $userId, name: $name) {
      ...FragmentSimulation
    }
  }
  ${FRAGMENT_SIMULATION}
`;

export const GET_SIMULATION_BY_ID = gql`
  query simulationById($userId: ID!, $id: ID) {
    simulationById(userId: $userId, id: $id) {
      ...FragmentSimulation
    }
  }
  ${FRAGMENT_SIMULATION}
`;
