import PropTypes from 'prop-types';
import React from 'react';

import { renderBubbleError } from '../../utils/errorHandler';

export default function Select(props) {
  const { label, name, onChange, defaultOption, value, values, className, error, required, disabled, onBlur, style } =
    props;

  const loading = props.loading ? true : false;
  const id = props.id || `select-${name}`;
  const testId = props['data-testid'] || id;

  return (
    <div
      className={`input input-select ${className}`}
      style={style}
      error={(error != null).toString()}
      required={required}
    >
      {label != null && (
        <label className="input-label" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="input-ctn">
        {error ? renderBubbleError(error) : null}
        <select
          id={id}
          name={name}
          onChange={onChange}
          required={required}
          value={value}
          disabled={disabled}
          onBlur={onBlur}
          data-testid={testId}
        >
          {defaultOption != null && <option value={-1}>{defaultOption}</option>}
          {loading || !values || values.length < 1
            ? null
            : Object.keys(values).map((key) => {
                const value = values[key];
                return (
                  <option key={`option-${key}-${value}`} value={key}>
                    {value.toString()}
                  </option>
                );
              })}
        </select>
      </div>
    </div>
  );
}

Select.defaultProps = {
  className: '',
  onChange: () => {},
};

Select.propTypes = {
  keyKey: PropTypes.string,
  valueKey: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.string,
  onChange: PropTypes.func,
};
