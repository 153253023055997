import { useMutation, useQuery } from '@apollo/react-hooks';
import { ExclamationTriangle } from '@styled-icons/bootstrap';
import dateFormat from 'dateformat';
import Lottie from 'lottie-react';
import React, { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
// Animation et CSS
import { toast } from 'react-toastify';
import squirrelSleeping from '../../assets/graphics/lottie/lf30_editor_qru8ucal.json';
// Appel des composants
import { Button } from '../../components/Button';
import DoughnutChart from '../../components/charts/DoughnutChart';
import { Dialog } from '../../components/Dialog';
import Dropdown from '../../components/Dropdown';
import Input from '../../components/Input';
import LabeledContainer from '../../components/LabeledContainer';
import { Menu } from '../../components/Menu';
import Select from '../../components/Select';
import SuperInput from '../../components/SuperInput';
// Appel des constantes
import { MONTHS, MONTH_PLAN } from '../../constants/constants';
// Appel des données en base
import { ADD_SIMULATION_SIMPLE, REM_SIMULATION, SET_SIMULATION } from '../../graphql/mutations/simulation';
import { GET_COMPUT_PARAMS } from '../../graphql/queries/computationsParameters';
import { GET_SIMULATIONS_NAMES, GET_SIMULATION_BY_ID } from '../../graphql/queries/simulation';
import { GET_NB_USERS_FACILITY, GET_USER } from '../../graphql/queries/users';
// Authentification
import { AuthContext } from '../../providers/AuthProvider/authProvider';
import { SpecificatorsContext } from '../../providers/SpecificatorsProvider/specificatorsProvider';
// Outils
import { hasErrors, onError, renderErrors } from '../../utils/errorHandler';
import { useFlaggedState } from '../../utils/hooks';
import {
  barchartDatasetDetails,
  chargesDetails,
  doughnutGraphDatasetDetails,
  getMonthDetails,
  otherIncomesDetails,
  yearlyBillingDetails,
} from '../../utils/simulation';
import { initialiserDefaultSpec } from '../../utils/specificators';
import { round } from '../../utils/utils';
export const Simulation = () => {
  const { user } = useContext(AuthContext);
  const [errors, setErrors] = useState();
  const [errorsAdd, setErrorsAdd] = useState();

  // simulation state
  const [yearPlan, setYearPlan] = useState({});
  const [simulation, setSimulation, simulationFlag, setSimulationFlag] = useFlaggedState({});

  // loading specificators
  const {
    loaded: { simulation: simulationSpecLoaded, yearPlan: yearPlanSpecLoaded, otherIncomes: otherIncomesSpecLoaded },
    specificators: {
      simulation: simulationSpecificator,
      monthPlan: monthPlanSpecificator,
      yearPlan: yearPlanSpecificator,
      otherIncomes: otherIncomesSpecificator,
    },
  } = useContext(SpecificatorsContext);

  // action states for conditionnal display
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);

  // new simulation date format
  const DATE_FORMAT = 'yyyy-mm-dd';
  // new simulation name state
  const [newSimu, setNewSimu] = useState({
    name: `Ma simu du : ${dateFormat(new Date(), DATE_FORMAT)}`,
  });

  // show states
  const [showMonthDetails, setShowMonthDetails] = useState(false);
  const [showOtherIncomes, setShowOtherIncomes] = useState(false);
  const [showCharges, setShowCharges] = useState(false);

  // select states
  const [selectedSimulation, setSelectedSimulation] = useState(-1);
  const [simulationsNames, setSimulationsNames] = useState([]);

  const simulationId = (value) => {
    return simulationsNames && simulationsNames[value] && simulationsNames[value].id;
  };

  // charges state
  const [charges, setCharges, chargesFlag] = useFlaggedState({});
  // useFlaggedState: hook intégrant un drapeau qui vérifie si les valeurs ont été modifiées

  // parameters used in the calculations
  const [nbUsersSameFacility, setNbUsersSameFacility] = useState(0);
  const [userInfos, setUserInfos, userInfosFlag] = useFlaggedState({});
  const [computParams, setComputParams, computParamsFlag] = useFlaggedState({});

  // charts states
  const [chargedWage, setChargedWage] = useState(0);
  const [doughtnutData, setDoughtnutData] = useState({});
  const [barchartDatasets, setBarchartDatasets] = useState([]);
  const [yearlyBilling, setYearlyBilling, yearlyBillingFlag] = useFlaggedState({
    value: 115500,
  });

  // month details
  const [monthDetails, setMonthDetails] = useState({});
  const [selectedMonthDetails, setSelectedMonthDetails, selectedMonthDetailsFlag] = useFlaggedState({});

  // -------------------------- BEGIN simulation state logic

  const setDaysByYear = (newYearPlan) => {
    setYearPlan(newYearPlan);
    const selectedDays = Object.values(simulation.monthsPlan || {})
      .map((m) => (m.selected ? m.days : 0))
      .reduce((p, c) => p + c, 0);
    // number of days - number of days that will not be changed
    const remainingDays = newYearPlan.days - selectedDays;
    if (remainingDays > 0) {
      const nbNotSelected = 12 - Object.values(simulation.monthsPlan).filter((m) => m.selected).length;
      const days = Number.parseInt(remainingDays / nbNotSelected);
      // computes the sum of the days of the selected months
      let restOfDays = remainingDays % nbNotSelected;
      const newSimulation = JSON.parse(JSON.stringify(simulation));
      Object.keys(MONTHS).forEach((m) => {
        if (newSimulation.monthsPlan[m] && !newSimulation.monthsPlan[m].selected) {
          newSimulation.monthsPlan[m].days = days;
          if (restOfDays > 0) newSimulation.monthsPlan[m].days++;
          restOfDays--;
        }
      });
      setSimulation({ ...newSimulation });
    }
  };

  const setDaysByMonth = (newSimulation) => {
    if (yearPlanSpecificator) {
      const days = Object.keys(MONTHS)
        .map((m) => newSimulation.monthsPlan[m].days)
        .reduce((p, c) => p + c, 0);
      const specDays = yearPlanSpecificator.fields.find((v) => v.name === 'days');
      if (specDays.max && days <= specDays.max && specDays.min && days >= specDays.min) {
        setYearPlan((oldState) => ({ ...oldState, days }));
        setSimulation({ ...newSimulation });
      }
    }
  };

  const setSimulationByYear = (newYearPlan) => {
    setYearPlan({ ...newYearPlan });
    let newMonthPlan = { ...simulation.monthsPlan };
    Object.keys(MONTHS).forEach((m) =>
      Object.keys(newYearPlan)
        .filter((key) => key !== 'days')
        .forEach((keyPlan) => {
          if (!newMonthPlan[m].selected) newMonthPlan[m][keyPlan] = newYearPlan[keyPlan];
        })
    );
    setSimulation((oldState) => ({
      ...oldState,
      monthsPlan: newMonthPlan,
    }));
  };

  const changeSimulation = (budg) => {
    setSimulation({
      ...budg,
      otherIncomes: {
        ...budg.otherIncomes,
        ...otherIncomesDetails(
          userInfos.contribution,
          chargedWage,
          yearPlan.days,
          budg.otherIncomes && budg.otherIncomes.mealTicket
        ),
      },
    });
    const newYearPlan =
      Object.values(budg.monthsPlan).filter((mP) => !mP.selected)[0] || Object.values(budg.monthsPlan)[0];
    const days = Object.values(budg.monthsPlan)
      .map((mP) => mP.days)
      .reduce((p, c) => p + c, 0);

    setYearPlan((oldState) => ({
      ...oldState,
      ...newYearPlan,
      days,
    }));
  };

  // END simulation state logic

  // -------------------------- BEGIN queries and mutations

  //------ begin fetch parameters

  useQuery(GET_COMPUT_PARAMS, {
    variables: {
      year: new Date().getFullYear(),
      facility: userInfos.facility,
    },
    skip: !userInfos.facility,
    onCompleted: (data) => {
      if (data) {
        setComputParams({
          ...data.computParams,
        });
      } else {
        console.log("The computations parameters haven't been defined for the current year");
      }
    },
    onError: (err) => {
      onError(setErrors, err);
    },
  });

  useQuery(GET_USER, {
    variables: { id: user.id },
    skip: !user.id || user.id.length < 1,
    onCompleted: (data) => {
      const userData = data && data.user;
      if (userData) {
        setUserInfos({ ...userData });
      }
    },
    onError: (err) => onError(setErrors, err),
  });

  useQuery(GET_NB_USERS_FACILITY, {
    variables: { facilityId: userInfos.facility },
    skip: !userInfos.facility || userInfos.facility.length < 1,
    onCompleted: (data) => {
      if (data && data.nbUsersFacility != null) {
        setNbUsersSameFacility(data.nbUsersFacility);
      }
    },
    onError: (err) => onError(setErrors, err),
  });
  // end fetch parameters

  const { loading: loadingSimulationsIdsDates, refetch: refetchSimulationsIdsDates } = useQuery(GET_SIMULATIONS_NAMES, {
    variables: {
      userId: user.id,
    },
    onCompleted: (data) => {
      if (data && data.simulationsNames) {
        delete data.simulationsNames.__typename;
        setSimulationsNames([...data.simulationsNames]);
        setErrors(null);
      }
    },
    onError: (err) => {
      onError(setErrors, err);
    },
  });

  const { loading: loadingSimulation, refetch: refetchSimulation } = useQuery(GET_SIMULATION_BY_ID, {
    variables: {
      userId: user.id,
      id: simulationId(selectedSimulation),
    },
    skip: selectedSimulation < 0 || !simulationId(selectedSimulation),
    onCompleted: (data) => {
      if (data && data.simulationById) {
        const { simulationById } = data;
        delete simulationById.__typename;
        delete simulationById.id;
        delete simulationById.user;
        delete simulationById.createdAt;
        delete simulationById.updatedAt;
        changeSimulation(simulationById);
        setErrors(null);
      }
    },
    onError: (err) => {
      onError(setErrors, err);
    },
  });

  // -------------------------- mutations

  const [updateSimulation, { loading: loadingUpdateSimulation }] = useMutation(SET_SIMULATION, {
    skip: selectedSimulation < 0 || !simulationId(selectedSimulation),
    onCompleted: async ({ setSimulation: newSimulation }) => {
      if (newSimulation) {
        delete newSimulation.__typename;
        delete newSimulation.id;
        delete newSimulation.createdAt;
        delete newSimulation.user;
        delete newSimulation.updatedAt;
        changeSimulation(newSimulation);
        setErrors(null);
        await refetchSimulationsIdsDates();
      }
    },
    onError: (err) => {
      onError(setErrors, err);
    },
  });

  const [addSimulation, { loading: loadingAddSimulation }] = useMutation(ADD_SIMULATION_SIMPLE, {
    variables: {
      userId: user.id,
      name: newSimu.name,
    },
    onCompleted: async (data) => {
      setIsAdding(false);
      if (data && data.addSimulationSimple) {
        await refetchSimulationsIdsDates();
        setSimulation(data.addSimulationSimple);
        setErrorsAdd(null);
        setSelectedSimulation(simulationsNames.length);
        setNewSimu({
          name: `simu_${dateFormat(new Date(), DATE_FORMAT)}`,
        });
      }
    },
    onError: (err) => onError(setErrorsAdd, err),
  });

  const [deleteSimulation, { loading: loadingDeleteSimulation }] = useMutation(REM_SIMULATION, {
    variables: {
      userId: user.id,
      id: simulationId(selectedSimulation),
    },
    onCompleted: async (data) => {
      setIsDeleting(false);
      if (data) {
        await refetchSimulationsIdsDates();
        setSelectedSimulation(-1);
        setErrors(null);
      }
    },
    onError: (err) => onError(setErrors, err),
  });

  const loadingOperation =
    loadingAddSimulation || loadingSimulation || loadingDeleteSimulation || loadingUpdateSimulation;

  // END queries and mutations

  // -------------------------- BEGIN Select change handlers

  const onChangeSelectedSimulation = async (event) => {
    const value = event.target.value;
    setSelectedSimulation(parseInt(value));
    setSimulationFlag(null);
    if (value > 0) {
      await refetchSimulation();
      await refetchSimulationsIdsDates();
    }
  };
  // END Select change handlers

  // -------------------------- BEGIN simulation useEffects

  // charges details
  useEffect(() => {
    setCharges({
      ...chargesDetails(userInfos, computParams, yearlyBilling.value, nbUsersSameFacility),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfosFlag, computParamsFlag, yearlyBillingFlag, nbUsersSameFacility]);

  // other incomes details
  useEffect(() => {
    setSimulation({
      ...simulation,
      otherIncomes: {
        ...simulation.otherIncomes,
        ...otherIncomesDetails(
          userInfos.contribution,
          chargedWage,
          yearPlan.days,
          simulation.otherIncomes && simulation.otherIncomes.mealTicket
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargedWage, yearPlan.days]);

  // yearly billing
  useEffect(() => {
    setYearlyBilling({ ...yearlyBillingDetails(simulation) });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationFlag]);

  // month details
  useEffect(() => {
    // sets the month details panel with the updated values
    if (
      selectedMonthDetails != null &&
      Object.keys(MONTHS)[selectedMonthDetails] != null &&
      simulation.monthsPlan &&
      simulation.monthsPlan[Object.keys(MONTHS)[selectedMonthDetails]]
    ) {
      setMonthDetails(
        getMonthDetails(selectedMonthDetails, simulation.monthsPlan, simulation.otherIncomes, computParams, charges)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationFlag, computParamsFlag, selectedMonthDetailsFlag]);

  // END simulation useEffects

  // -------------------------- BEGIN charts useEffects

  // doughnut graph data
  useEffect(() => {
    const newDoughtnutDataset = doughnutGraphDatasetDetails(
      simulation,
      computParams,
      simulation.otherIncomes,
      charges,
      yearlyBilling.value
    );
    setChargedWage(newDoughtnutDataset.chargedWage.value);
    const doughnutData = Object.values(newDoughtnutDataset);
    const clickHandlers = Object.keys(newDoughtnutDataset).map((key) => {
      switch (key) {
        case 'otherIncomes':
          return () => {
            setShowMonthDetails(false);
            setShowOtherIncomes((prevState) => !prevState);
          };
        case 'totalCharges':
          return () => {
            setShowMonthDetails(false);
            setShowCharges((prevState) => !prevState);
          };
        default:
          return null;
      }
    });
    setDoughtnutData({
      labels: doughnutData.map((v) => v.label),
      descriptions: doughnutData.map((v) => v.description),
      legendClickHandlers: clickHandlers,
      datasets: [
        {
          label: 'Simulation',
          backgroundColor: doughnutData.map((v) => v.color),
          data: doughnutData.map((v) => round(v.value, 0)),
          outlabels: {
            color: 'rgb(0,0,0)',
          },
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationFlag, computParamsFlag, chargesFlag, yearlyBillingFlag]);

  // histogram variables
  useEffect(() => {
    setBarchartDatasets(barchartDatasetDetails(simulation.monthsPlan, simulation.otherIncomes, computParams, charges));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationFlag, computParamsFlag]);

  // END charts useEffects

  // -------------------------- BEGIN methods for renaming the simulation

  /**
   * method called to change the name of the simulation
   */
  const onRename = (event) => {
    const value = event.target.value || '';
    const name = value.toString();
    const newSimulationNames = [...simulationsNames];
    setSimulation({ ...simulation, name });
    newSimulationNames[selectedSimulation].name = name;
    setSimulationsNames(newSimulationNames);
  };

  const toggleRenaming = () => setIsRenaming(!isRenaming);

  // END methods for renaming the simulation

  // -------------------------- BEGIN initialising states using specificators

  // SIMULATION
  useEffect(() => {
    if (selectedSimulation < 0 && simulationSpecLoaded) {
      const budg = initialiserDefaultSpec({}, simulationSpecificator);
      setSimulation((prevState) => ({ ...prevState, ...budg }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSimulation, simulationSpecLoaded]);

  // SIMULATION YEAR PLAN
  useEffect(() => {
    if (selectedSimulation < 0 && yearPlanSpecLoaded) {
      const defaultYearPlan = initialiserDefaultSpec({}, yearPlanSpecificator);
      let monthsPlan = {};
      let days = Number.parseInt(defaultYearPlan.days / 12);
      let restOfDays = defaultYearPlan.days % 12;
      Object.keys(MONTHS).forEach((m) => {
        monthsPlan[m] = { ...defaultYearPlan, days };
        if (restOfDays > 0) monthsPlan[m].days++;
        restOfDays--;
      });
      setYearPlan(defaultYearPlan);
      setSimulation((prevState) => ({
        ...prevState,
        monthsPlan,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSimulation, yearPlanSpecLoaded]);

  // SIMULATION OTHER INCOMES //
  useEffect(() => {
    if (selectedSimulation < 0 && otherIncomesSpecLoaded) {
      const otherIncomesDef = initialiserDefaultSpec(simulation.otherIncomes, otherIncomesSpecificator);
      // updating state

      setSimulation((prevState) => ({
        ...prevState,
        otherIncomes: {
          ...prevState.otherIncomes,
          ...otherIncomesDef,
          ...otherIncomesDetails(
            userInfos.contribution,
            chargedWage,
            yearPlan.days,
            otherIncomesDef && otherIncomesDef.mealTicket
          ),
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSimulation, otherIncomesSpecLoaded]);

  useEffect(() => {
    if (selectedSimulation > -1 && simulation.otherIncomes.mealTicket > 0) {
      if (!userInfos.mealTicket && simulation.name) {
        toast.warning(
          <div style={{ alignItems: 'center' }}>
            <div style={{ width: 24, marginBottom: 8 }}>
              <ExclamationTriangle />
            </div>
            <div className="warningMealticket">
              <p>
                Cette simulation intègre des Tickets restaurant dans ses calculs alors que votre trésorerie n'inclut
                plus ce type de revenu.
                <br />
                Réalisez une nouvelle simulation pour une meilleure projection :)
              </p>
            </div>
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 10000,
            role: 'alert',
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulation.name]);

  // END initialising states using specificators

  const checkSubmissible = () => {
    const simulationHasError = hasErrors(simulation, simulationSpecificator);
    const yearPlanHasError = hasErrors(yearPlan, yearPlanSpecificator);
    const monthsPlanHasError = Object.keys(MONTHS)
      .map((k) => simulation.monthsPlan && hasErrors(simulation.monthsPlan[k], monthPlanSpecificator))
      .reduce((p, c) => p || c);

    return (
      !loadingOperation && selectedSimulation >= 0 && !simulationHasError && !yearPlanHasError && !monthsPlanHasError
    );
  };

  const submitDisabled = !checkSubmissible();

  const onSubmit = (event) => {
    event.preventDefault();
    if (!submitDisabled)
      updateSimulation({
        variables: {
          userId: user.id,
          id: simulationId(selectedSimulation),
          simulation,
        },
      });
  };

  function onClickSubmit() {
    checkSubmissible();
  }

  /**
   *
   * @param {Boolean} name from specificators(on server)
   * @return {{cases}}
   * Disabled or enabled an element
   */
  const getOtherIncomesActivator = (name) => {
    switch (name) {
      // if mealTicket is true, disabled is false, and vice versa.
      case 'mealTicket':
        return !userInfos.mealTicket;
      default:
        return false;
    }
  };

  //Variabilization of MealTickets and MealPacks depending on the calculation parameters and the number of days selected
  const getOtherIncomeSpecificators = () => {
    const indexMealTicket = otherIncomesSpecificator.fields.findIndex((field) => field.name === 'mealTicket');
    const indexMealPack = otherIncomesSpecificator.fields.findIndex((field) => field.name === 'mealPack');
    if (indexMealTicket !== -1) {
      otherIncomesSpecificator.fields[indexMealTicket] = {
        ...otherIncomesSpecificator.fields[indexMealTicket],
        max:
          (computParams.mealTicket * yearPlan.days).toFixed(2) || otherIncomesSpecificator.fields[indexMealTicket].max,
        step: computParams.mealTicket || otherIncomesSpecificator.fields[indexMealTicket].step,
      };
    }
    if (indexMealPack !== -1) {
      otherIncomesSpecificator.fields[indexMealPack] = {
        ...otherIncomesSpecificator.fields[indexMealPack],
        max: (computParams.mealPack * 60).toFixed(2) || otherIncomesSpecificator.fields[indexMealPack].max,
        step: computParams.mealPack || otherIncomesSpecificator.fields[indexMealPack].step,
      };
    }
    return { ...otherIncomesSpecificator };
  };

  return (
    <div className="row whole-height fill">
      <Menu />
      <main className="block column fill">
        <div className={`centered-block fill`}>
          <div className="white-container fill">
            <form onSubmit={onSubmit} className="column w-100 whole-height marg-between-b">
              <h1 className="w-100 titlePage">Simulation</h1>
              <hr></hr>
              <div className="row actionBar marg-b align-b marg-between-r">
                <div className="create">
                  {/* ---
                Adds new simulation 
                */}
                  <Button
                    className={`buttonCreate ${simulationsNames.length === 0 && 'pulse'}`}
                    label={'Créer une simulation'}
                    plain={true}
                    callback={() => {
                      setIsAdding(true);
                    }}
                  />
                  {isAdding && (
                    <Dialog
                      title={"Ajout d'une simulation"}
                      disclaimer={`Vous allez créer une nouvelle simulation`}
                      successLabel={'Continuer'}
                      success={async () => {
                        await addSimulation();
                      }}
                      cancel={() => {
                        setIsAdding(false);
                      }}
                    ></Dialog>
                  )}
                  {isAdding && (
                    <Dialog
                      title={'Réaliser une nouvelle simulation'}
                      successLabel={'Créer'}
                      success={async () => {
                        await addSimulation();
                      }}
                      cancel={() => {
                        setIsAdding(false);
                      }}
                    >
                      <SuperInput
                        label={'Nom de la simulation'}
                        state={newSimu}
                        setState={setNewSimu}
                        specificator={simulationSpecificator}
                        path={`name`}
                        errors={errorsAdd}
                        setErrors={setErrorsAdd}
                      />
                    </Dialog>
                  )}
                </div>
                {/* ---
                Renames a selected simulation 
                */}
                <div className="actionsOnRecord">
                  {isRenaming ? (
                    <Input label="Simulation" name="name" value={simulation.name} onChange={onRename} />
                  ) : (
                    simulationsNames.length > 0 && (
                      <Select
                        label
                        hidden="Liste des simulations"
                        name="simulation"
                        defaultOption={'Sélectionner une simulation'}
                        value={selectedSimulation}
                        values={simulationsNames.map((v) => v.name)}
                        onChange={onChangeSelectedSimulation}
                        loading={loadingSimulationsIdsDates}
                        style={{ flexDirection: 'row' }}
                      />
                    )
                  )}
                  {selectedSimulation !== -1 && (
                    <Button
                      label={isRenaming ? 'Appliquer' : 'Renommer'}
                      plain={isRenaming}
                      callback={toggleRenaming}
                    />
                  )}
                  {/* ---
                  Deletes a selected simulation 
                  */}
                  {selectedSimulation >= 0 && (
                    <Button
                      label={'Supprimer'}
                      plain={true}
                      callback={() => {
                        setIsDeleting(true);
                      }}
                    />
                  )}
                  {!submitDisabled && (
                    <div className="row-reverse fill align-b">
                      <Input value={'Enregistrer'} type="submit" onClick={onClickSubmit} />
                    </div>
                  )}
                </div>
              </div>

              {/* ---
              SIMULATION TOOL 
              Display if the list of simulations is not empty
              */}
              {selectedSimulation !== -1 ? (
                <section className="simulationTool">
                  <div className="column w-100 wrapper marg-between-b">
                    <div className="row w-100 marg-between-r marg-between-b space-between wrapper">
                      <div className="column labeled-block flex-3 marg-s-auto">
                        <h4>Moyenne annuelle</h4>

                        <div className="column space-between marg-between-r">
                          {/* Curseurs de Moyenne annuelle */}
                          <div className="row">
                            <div className="column col-is-9">
                              <SuperInput
                                label="Nombre de jours travaillés"
                                type={'range'}
                                state={yearPlan}
                                setState={setDaysByYear}
                                specificator={yearPlanSpecificator}
                                path={`days`}
                                errors={errors}
                                setErrors={setErrors}
                                className={'fill'}
                                showInterval
                              />
                            </div>
                            <div className="column col-is-3">
                              <SuperInput
                                state={yearPlan}
                                setState={setDaysByYear}
                                specificator={yearPlanSpecificator}
                                path={`days`}
                                errors={errors}
                                setErrors={setErrors}
                                className="small"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="column col-is-9">
                              <SuperInput
                                label="Salaire mensuel net optimal"
                                type={'range'}
                                state={yearPlan}
                                setState={setSimulationByYear}
                                specificator={yearPlanSpecificator}
                                path={`wage`}
                                errors={errors}
                                setErrors={setErrors}
                                className={'fill'}
                                showInterval
                              />
                            </div>
                            <div className="column col-is-3">
                              <SuperInput
                                state={yearPlan}
                                setState={setSimulationByYear}
                                specificator={yearPlanSpecificator}
                                path={`wage`}
                                errors={errors}
                                setErrors={setErrors}
                                className="small"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="column col-is-9">
                              <SuperInput
                                label="Km domicile <-> mission A/R"
                                type={'range'}
                                state={yearPlan}
                                setState={setSimulationByYear}
                                specificator={yearPlanSpecificator}
                                path={`kmDay`}
                                errors={errors}
                                setErrors={setErrors}
                                className={'fill'}
                                showInterval
                              />
                            </div>
                            <div className="column col-is-3">
                              <SuperInput
                                state={yearPlan}
                                setState={setSimulationByYear}
                                specificator={yearPlanSpecificator}
                                path={`kmDay`}
                                errors={errors}
                                setErrors={setErrors}
                                className="small"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="column col-is-9">
                              <SuperInput
                                label="TJM de la mission"
                                type={'range'}
                                state={yearPlan}
                                setState={setSimulationByYear}
                                specificator={yearPlanSpecificator}
                                path={`billing`}
                                errors={errors}
                                setErrors={setErrors}
                                className={'fill'}
                                showInterval
                              />
                            </div>
                            <div className="column col-is-3">
                              <SuperInput
                                state={yearPlan}
                                setState={setSimulationByYear}
                                specificator={yearPlanSpecificator}
                                path={`billing`}
                                errors={errors}
                                setErrors={setErrors}
                                className="small"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="array column flex-7 minw-prct-60 labeled-block">
                        <div className="row space-between marg-between-r padd-s">
                          {Object.keys(MONTHS).map((month) => (
                            <SuperInput
                              state={simulation}
                              label={MONTHS[month]}
                              setState={setSimulation}
                              specificator={monthPlanSpecificator}
                              path={`monthsPlan.${month}.selected`}
                              errors={errors}
                              setErrors={setErrors}
                              className={'small wr-4'}
                              salt={month}
                              key={`tick-${month}`}
                            />
                          ))}
                        </div>
                        <div className="row space-between marg-between-r fill marg-b">
                          {Object.keys(MONTHS).map((month) => (
                            <div className="column wr-4 centered" key={month}>
                              {MONTH_PLAN.map((plan) => (
                                <SuperInput
                                  key={`${plan}-${month}`}
                                  state={simulation}
                                  setState={(simulation) => {
                                    if (simulation.monthsPlan[month].selected) {
                                      if (plan === 'days') setDaysByMonth(simulation);
                                      else setSimulation(simulation);
                                    }
                                  }}
                                  specificator={monthPlanSpecificator}
                                  path={`monthsPlan.${month}.${plan}`}
                                  errors={errors}
                                  setErrors={setErrors}
                                  disabled={
                                    simulation.monthsPlan &&
                                    simulation.monthsPlan[month] &&
                                    !simulation.monthsPlan[month].selected
                                  }
                                  className={'small'}
                                  salt={month}
                                />
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row w-100 marg-between-r space-between wrapper">
                    <div className="minw-25r flex-3">
                      <DoughnutChart
                        data={doughtnutData}
                        middleTitle={`${yearlyBilling.label}*`}
                        middleDescription={yearlyBilling.description}
                        middleValue={round(yearlyBilling.value, 0)}
                      />
                      <p style={{ color: '#A1A1A6', fontSize: 12, paddingLeft: 12 }}>
                        * Chiffre d'affaires personnel de l'exercice
                      </p>
                    </div>
                    <div className="flex-7 wrapper minw-prct-60">
                      <Bar
                        id="histogram"
                        height={70}
                        options={{
                          hover: {
                            onHover: function (_, activeElements) {
                              document.getElementById('histogram').style.cursor =
                                activeElements.length > 0 ? 'pointer' : 'default';
                            },
                          },
                          onClick: function (event) {
                            const elt = this.chart.getElementAtEvent(event)[0];
                            if (elt != null) {
                              // hide the charges and other incomes panels
                              setShowCharges(false);
                              setShowOtherIncomes(false);
                              // if the previous month has been click
                              // or the month details are not displayed yet
                              if (selectedMonthDetails === elt._index || !showMonthDetails) {
                                // toggle the show state of the month details panel
                                setShowMonthDetails(!showMonthDetails);
                              }
                              setSelectedMonthDetails(elt._index);
                            }
                          },
                          scales: {
                            // stack all the datasets
                            // onto the same label
                            xAxes: [
                              {
                                stacked: true,
                              },
                            ],
                            yAxes: [
                              {
                                stacked: true,
                              },
                            ],
                          },
                        }}
                        legend={{
                          display: false,
                        }}
                        data={{
                          labels: Object.values(MONTHS),
                          datasets: barchartDatasets,
                        }}
                      />
                    </div>
                  </div>
                  <div className="row marg-between-r centered wrapper">
                    {showOtherIncomes &&
                      simulation.otherIncomes &&
                      otherIncomesSpecificator &&
                      otherIncomesSpecificator.fields &&
                      otherIncomesSpecificator.fields.length > 0 && (
                        <div className="labeled-block marg-b">
                          <h3>Détail des autres revenus (ce qui est touché à l’année)</h3>
                          <div className="row">
                            <LabeledContainer
                              values={Object.values(otherIncomesSpecificator.fields)}
                              callback={(othInc, index) => (
                                <SuperInput
                                  key={`${othInc.name}-${index}`}
                                  showLabel={true}
                                  errors={errors}
                                  setErrors={setErrors}
                                  state={simulation.otherIncomes}
                                  setState={(data) => {
                                    setSimulation({
                                      ...simulation,
                                      otherIncomes: {
                                        ...otherIncomesDetails(
                                          userInfos.contribution,
                                          chargedWage,
                                          yearPlan.days,
                                          simulation.otherIncomes && simulation.otherIncomes.mealTicket
                                        ),
                                        ...simulation.otherIncomes,
                                        ...data,
                                      },
                                    });
                                  }}
                                  path={`${othInc.name}`}
                                  specificator={getOtherIncomeSpecificators()}
                                  disabled={getOtherIncomesActivator(othInc.name)}
                                  className="row small"
                                />
                              )}
                            />
                          </div>
                        </div>
                      )}
                    {showCharges && charges && Object.values(charges).length > 0 && (
                      <div className="labeled-block marg-b">
                        <h3>Détail des charges (gestion et taxes)</h3>
                        <div className="row">
                          <LabeledContainer
                            values={Object.values(charges)}
                            callback={(charge, index) => (
                              <Input
                                key={`${charge.label}-${index}`}
                                label={charge.label}
                                value={round(charge.value, 0)}
                                type="number"
                                className="row space-between marg-between-r small"
                                disabled={true}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}
                    {showMonthDetails && (
                      <div className="labeled-block marg-b">
                        <h3>{`Détail du mois de ${monthDetails.label}`}</h3>
                        <div className="column">
                          <div className="input row marg-between-r space-between">
                            <h4 className="input-label flex-1">Chiffre d'affaires</h4>
                            <span>{monthDetails.turnover}</span>
                          </div>
                          <br />
                          <Dropdown title="Revenu mensuel tout compris">
                            <div className="input row marg-between-r space-between">
                              <h4 className="input-label flex-1">Salaire net</h4>
                              <span>{monthDetails.wage}</span>
                            </div>
                            <div className="input row marg-between-r space-between">
                              <h4 className="input-label flex-1">IK</h4>
                              <span>{`+ ${monthDetails.ik}`}</span>
                            </div>
                            <div className="input row marg-between-r space-between">
                              <h4 className="input-label flex-1">Autres revenus du mois</h4>
                              <div className="column">
                                <span>{`+ ${monthDetails.otherIncomes}`}</span>
                                <span className="breaker" />
                              </div>
                            </div>
                          </Dropdown>
                          <div className="row marg-b">
                            <div className="flex-1"></div>
                            <span>{`- ${monthDetails.wageAllIncluded}`}</span>
                          </div>
                          <div className="input row marg-between-r space-between">
                            <h4 className="input-label flex-1">Charges au mois</h4>
                            <div className="column">
                              <span>{`- ${monthDetails.charges}`}</span>
                              <span className="breaker" />
                            </div>
                          </div>
                          <div className="input row marg-between-r space-between">
                            <h4 className="input-label flex-1">Solde mensuel</h4>
                            <span>{`= ${monthDetails.balance}`}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </section>
              ) : (
                <section className="emptyState centered-block">
                  <div>
                    <Lottie className="emptySvg centered-block" animationData={squirrelSleeping} />
                    <h3 className="emptyTitle">C'est un peu vide ici pour l'instant ...</h3>
                  </div>
                  <div className="emptyProposition">
                    {simulationsNames.length === 0 ? (
                      <p>Cliquez sur Créer une simulation pour réaliser votre premier essai.</p>
                    ) : (
                      <p>
                        Selectionnez une de vos simulations dans la liste ou cliquez sur Créer pour en réaliser une
                        nouvelle.
                      </p>
                    )}
                  </div>
                </section>
              )}
            </form>
            {renderErrors(errorsAdd)}
          </div>
        </div>
      </main>
      {loadingOperation && <div className="card wall loading"></div>}
      {isDeleting && (
        <Dialog
          title={"Suppression d'une simulation"}
          disclaimer={`
						Voulez-vous vraiment supprimer la simulation
						"${simulation.name}".
					`}
          success={async () => {
            await deleteSimulation();
          }}
          cancel={() => {
            setIsDeleting(false);
          }}
        ></Dialog>
      )}
    </div>
  );
};
