import PropTypes from 'prop-types';
import React from 'react';

export const MenuItem = (props) => {
  const { showlabel, label, path, icon: Icon, pic } = props;
  const illustration = Icon || pic;
  return (
    <li className="menu-item" title={label} {...props}>
      <a href={path}>
        {!showlabel && illustration ? null : <span>{label}</span>}
        {!illustration ? null : (
          <figure className="icon">
            {!Icon ? null : <Icon alt={label} />}
            {!pic ? null : <img src={pic} alt={label} />}
          </figure>
        )}
      </a>
    </li>
  );
};

MenuItem.defaultProps = {
  showlabel: 0,
  path: '/',
};

MenuItem.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
};
