import { MONTHS } from '../constants/constants';
import { round } from './utils';

const CONTRIBUTION = 1000;
export const MAX_DEPTH = 10;

export const otherIncomesDetails = (contribution, chargedWage, days, mealTicket) => {
  return {
    profitSharing: Number.parseInt(chargedWage * 0.14) || 0,
    // Calcul : (Salaire chargé annuel - (salaire chargé annuel x 0,3)) x 0,2
    // => Salaire chargé annuel x 0,14
    contribution: contribution || CONTRIBUTION,
    // mealTicket: Math.max(Math.min(mealTicket || 0, Number.parseInt(days * mealTicket)), 0),
    // Calcul : Nombre de jours travaillés x montant du Ticket Restau
  };
};

/**
 *
 * @param {{*}} user
 * @param {{*}} computParams
 * @param {Number} yearlyBilling
 * @param {Number} nbUsersSameFacility
 * @return {{label: String, value: Number}}
 * the details of the charges applying to a user
 */
export const chargesDetails = (user, computParams, yearlyBilling, nbUsersSameFacility) => {
  const nbUsersFacility = Math.max(1, nbUsersSameFacility);
  return [
    {
      label: 'Commission',
      value: yearlyBilling * user.commissionRate || 0,
      description: 'Facturation annuelle x taux de commission de gérance',
    },
    {
      label: 'Provision intercontrat',
      value: yearlyBilling * computParams.interContractsProvisionRate || 0,
      description: 'Facturation annuelle x taux de provision intercontrat',
    },
    {
      label: 'RFA client',
      value: yearlyBilling * computParams.clientRetrocommission || 0,
      description: 'Facturation annuelle x taux de rétrocommission client',
    },
    {
      label: 'Taxes voiture fonction',
      value: user.companyCar ? 2000 : 0,
      description: 'Montant des différentes taxes liées à la voiture de fonction',
    },
    {
      label: 'Fiches de paie',
      value: computParams.payrollSoftwareCost / nbUsersFacility || 0,
      description: 'Coût du logiciel de paie réparti entre les salariés',
    },
    {
      label: 'Cabinet comptable',
      value: computParams.accountingFirmCost / nbUsersFacility || 0,
      description: 'Coût du cabinet comptable réparti entre les salariés',
    },
    {
      label: 'Taxe CFE',
      value: computParams.cfe / nbUsersFacility || 0,
      description: 'Cotisation foncière des entreprises (CFE) répartie entre les salariés',
    },
    {
      label: 'Taxe CVAE',
      value: computParams.cvae / nbUsersFacility || 0,
      description: 'Cotisation sur la valeur ajoutée des entreprises (CVAE) répartie entre les salariés',
    },
    {
      label: 'Taxe médecine du travail',
      value: computParams.occupationalHealth / nbUsersFacility || 0,
      description: 'Cotisation médecine du travail répartie entre les salariés',
    },
    {
      label: 'RCP',
      value: computParams.rcp / nbUsersFacility || 0,
      description: 'Cotisation RCP répartie entre les salariés',
    },
    {
      label: 'Taxes formation',
      value: computParams.vocationalTrainingTax / nbUsersFacility || 0,
      description: 'Cotisation pour notre organisme collecteur (Atlas) répartie entre les salariés',
    },
    {
      label: 'Domiciliation',
      value: computParams.legalCost / nbUsersFacility || 0,
      description: "Coût de domiciliation de l'entreprise chez Kikbox répartie entre les salariés",
    },
  ];
};

export const yearlyBillingDetails = (simulation) => {
  // TJM de la mission x Nombre de jours travaillés
  // Survol : montant + explication : "La facturation annuelle correspond au chiffre d'affaires personnel de l'exercice"
  // Clic : pas d'action

  const definedSimulation = simulation != null && simulation.monthsPlan != null;

  let days = 0,
    billing = 0;

  if (definedSimulation) {
    days = Object.values(simulation.monthsPlan)
      .map((v) => v.days)
      .reduce((p, c) => p + c, 0);
    billing =
      Object.values(simulation.monthsPlan)
        .map((v) => v.billing)
        .reduce((p, c) => p + c, 0) / 12;
  }

  return {
    label: 'Facturation annuelle',
    value: days * billing || 0,
    description: "La facturation annuelle correspond au chiffre d'affaires personnel de l'exercice",
  };
};

export const getMonthDetails = (selectedMonth, monthsPlan, otherIncomes, computParams, charges) => {
  const monthData = monthsPlan[Object.keys(MONTHS)[selectedMonth]];
  const monthTurnover = monthData.days * monthData.billing;
  const monthOtherIncomes = (otherIncomes && Object.values(otherIncomes).reduce((p, c) => p + c) / 12) || 0;
  const ik = monthData.days * monthData.kmDay * computParams.mileageScale;
  const wageAllIncluded = monthData.wage + ik + monthOtherIncomes;
  const monthCharges =
    charges &&
    Object.values(charges).length > 0 &&
    Object.values(charges)
      .map((c) => c.value)
      .reduce((p, c) => p + c) /
      12 +
      monthData.wage * computParams.chargedWageRate;

  return {
    label: Object.values(MONTHS)[selectedMonth],
    turnover: round(monthTurnover, 0),
    wage: round(monthData.wage, 0),
    ik: round(ik, 0),
    otherIncomes: round(monthOtherIncomes, 0),
    wageAllIncluded: round(wageAllIncluded, 0),
    charges: round(monthCharges, 0),
    balance: round(monthTurnover - wageAllIncluded - monthCharges, 0),
  };
};

export const barchartDatasetDetails = (monthsPlan, otherIncomes, computParams, charges) => {
  const monthsTotalChargedWages = (monthsPlan &&
    Object.keys(MONTHS).map((v) => monthsPlan[v] && monthsPlan[v].wage * (1 + computParams.chargedWageRate))) || [0];

  const monthsTotalIKs = (monthsPlan &&
    Object.keys(MONTHS).map(
      (v) => monthsPlan[v] && monthsPlan[v].days * monthsPlan[v].kmDay * computParams.mileageScale
    )) || [0];

  const monthsOtherIncomes = Object.keys(MONTHS).map(
    (_) => (otherIncomes && Object.values(otherIncomes).reduce((p, c) => p + c) / 12) || 0
  ) || [0];

  const monthsTotalCharges = Object.keys(MONTHS).map(
    (_) =>
      (charges &&
        Object.values(charges).length > 0 &&
        Object.values(charges)
          .map((c) => c.value)
          .reduce((p, c) => p + c) / 12) ||
      0
  ) || [0];

  const monthsBalances = (monthsPlan &&
    Object.keys(MONTHS).map(
      (v, i) =>
        monthsPlan[v] &&
        monthsPlan[v].days * monthsPlan[v].billing -
          (monthsTotalChargedWages[i] + monthsTotalIKs[i] + monthsOtherIncomes[i] + monthsTotalCharges[i])
    )) || [0];

  return [
    {
      name: 'chargedWage',
      type: 'bar',
      label: 'Total salaire chargé',
      backgroundColor: Object.values(MONTHS).map((_) => '#06AAAC'),
      data: monthsTotalChargedWages.map((v) => round(v, 0)),
    },
    {
      name: 'ik',
      type: 'bar',
      label: 'Total IK',
      backgroundColor: Object.values(MONTHS).map((_) => '#EABE35'),
      data: monthsTotalIKs.map((v) => round(v, 0)),
    },
    {
      name: 'otherIncomes',
      type: 'bar',
      label: 'Autres revenus',
      backgroundColor: Object.values(MONTHS).map((_) => '#9E70D9'),
      data: monthsOtherIncomes.map((v) => round(v, 0)),
    },
    {
      name: 'charges',
      type: 'bar',
      label: 'Charges totales',
      backgroundColor: Object.values(MONTHS).map((_) => '#7EE8E9'),
      data: monthsTotalCharges.map((v) => round(v, 0)),
    },
    {
      name: 'balance',
      type: 'bar',
      label: 'Solde',
      backgroundColor: Object.values(MONTHS).map((_, i) => (monthsBalances[i] > 0 ? '#13DF80' : '#FE201F')),
      data: monthsBalances.map((v) => round(v, 0)),
    },
  ];
};

export const doughnutGraphDatasetDetails = (
  simulation,
  computParams,
  otherIncomes = {},
  charges = {},
  yearlyBilling
) => {
  const definedSimulation = simulation != null && simulation.monthsPlan != null;

  let kmYear = 0,
    wage = 0;

  if (definedSimulation) {
    Object.values(simulation.monthsPlan).forEach((v) => {
      wage += v.wage;
      kmYear += v.days * v.kmDay;
    });
  }

  const otherIncomesTotal = Object.values(otherIncomes).reduce((p, c) => p + c, 0) || 0;

  const chargesTotal =
    Object.values(charges)
      .map((v) => v.value)
      .reduce((p, c) => p + c, 0) || 0;
  const chargedWage = wage * (computParams.chargedWageRate + 1) || 0;
  const totalIK = kmYear * computParams.mileageScale || 0;
  const balance = yearlyBilling - (chargedWage + totalIK + otherIncomesTotal + chargesTotal);

  return {
    chargedWage: {
      label: 'Total Salaire Chargé',
      value: chargedWage,
      color: '#06AAAC',
      description: `Le total du salaire chargé est le coût total du salaire pour l'entreprise c'est à dire le salaire net + les charges salariales + les charges patronales`,
    },
    ik: {
      label: 'Total IK',
      value: totalIK,
      color: '#EABE35',
      description: `Le total des Indemnités Kilométriques de l'année est le nombre de KM effectués dans l'année x le nombre de jours travaillés x le barème IK des impôts pour un véhicule de 7CV et plus. Attention, ce montant est une estimation qui est revue sur l'année civile sur la base du véritable taux de votre véhicule`,
    },
    otherIncomes: {
      label: 'Autres revenus',
      value: otherIncomesTotal,
      color: '#9E70D9',
      description: `Les autres revenus sont les éléments de rémunération non salariale dont vous trouverez le détail en cliquant sur cette zone`,
    },
    totalCharges: {
      label: 'Charges totales',
      value: chargesTotal,
      color: '#7EE8E9',
      description: `Les charges totales sont les dépenses soit indéxées sur le chiffre d'affaires annuel (taux de commission, intercontrat, RFA), soit les coûts entreprise réparties sur chaque salarié (gestion de la paye, comptabilité, taxes...). Vous trouverez le détail en cliquant sur cette zone`,
    },
    balance: {
      label: 'Solde',
      value: balance,
      color: balance > 0 ? '#13DF80' : '#FE201F',
      description: `Votre solde est la différence entre la facturation annuelle (votre chiffre d'affaires) et les différents coûts (salaire chargé + IK + autres revenus + charges). Il est préférable de garder un "matelas" d'environ 4 000€. Le reliquat de solde présent en fin d'exercice sera versé sous forme de prime (sous la condition d'un solde multi-exercice positif)`,
    },
  };
};
