// BDD
import { useMutation } from '@apollo/react-hooks';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
// Tools
import VerificationInput from 'react-verification-input';
import { Button } from '../../components/Button';
// Components & package
import HistoryLink from '../../components/HistoryLink';
import InputPassword from '../../components/InputPassword';
import { ValidationRules } from '../../components/ValidationRules';
import { FORM_MINLENGTH } from '../../constants/constants';
import { CHANGE_PASSWORD, FORGOT_PASSWORD } from '../../graphql/mutations/login';
import { SpecificatorsContext } from '../../providers/SpecificatorsProvider/specificatorsProvider';
// Utils
import { errorFind, onError, renderErrors } from '../../utils/errorHandler';

const LIMIT_TRY = 3;
const securityLevels = [
  {
    descriptionLabel: '1 chiffre',
    validator: /.*[0-9].*/,
  },
  {
    descriptionLabel: '1 minuscule',
    validator: /.*[a-z].*/,
  },
  {
    descriptionLabel: '1 majuscule',
    validator: /.*[A-Z].*/,
  },
  {
    descriptionLabel: '1 caractère spécial',
    validator: /.*[#?!@$%^&*-].*/,
  },
  {
    descriptionLabel: '8 caractères',
    validator: /^.{8,}$/,
  },
];

export const ResetPassword = () => {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [nbrClick, setNbrClick] = useState(0);
  const [inputRef, setInputRef] = useState('0');
  const [passwordField, setPasswordField] = useState('');
  const [passwordFieldConfirm, setPasswordFieldConfirm] = useState('');
  const [isSamePwd, setIsSamePwd] = useState(false);
  const { loading: loadingSpecificators } = useContext(SpecificatorsContext);
  // for ValidationRulesComponent
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  // --- ---
  // --- --- SECTION PASSWORD PROCESS --- --- //

  // Handle new password
  const handleChange = (event) => {
    setPasswordField(event.target.value);
  };

  // Handle confirm password
  const handleConfirmChange = (event) => {
    setPasswordFieldConfirm(event.target.value);
  };

  // Check PasswordField & PasswordFieldConfirm are same
  const isSamePassword = (passwordField, passwordFieldConfirm, isPasswordValid) => {
    if ((isPasswordValid === true) & (passwordField === passwordFieldConfirm)) {
      setIsSamePwd(true);
      toast.success('Un email vous a été envoyé :)');
    } else {
      toast.error('Oups, les deux mots de passe ne sont pas identiques');
    }
  };

  // --- ---
  // --- SECTION SECURITY CODE PROCESS --- //

  // Recovery the securityCode from ForgotPassword and compare with the user's input
  const verifyMyCode = () => {
    const codeSent = history.location.state.detail;
    setNbrClick(nbrClick + 1);

    if (inputRef.value === codeSent.toString()) {
      // appel de la mutation de changement de mot de passe
      resetPassword({
        variables: {
          confirmPassword: passwordFieldConfirm,
          password: passwordField,
          email: history.location.state.email,
        },
      });
    } else {
      toast.error('Oups, le code saisi ne correspond pas au code envoyé');
    }
  };

  // Resend security code after 3 failed tests
  const [sendSecurityCode] = useMutation(FORGOT_PASSWORD, {
    onError: (err) => onError(setErrors, err),
    onCompleted: (data) => {
      // data ===> codeToVerify from back end
      toast.success('Un nouvel email vous a été envoyé :)');
      history.push({
        pathname: '/resetPassword',
        state: {
          detail: data.forgotPassword,
          email: history.location.state.email,
        },
      });
    },
  });

  // Reinitialize the password on database and redirect to Login view
  const [resetPassword, { loading: loadingChangePassword }] = useMutation(CHANGE_PASSWORD, {
    onError: (err) => onError(setErrors, err),
    // specify the variables that the mutation requires on login.js
    onCompleted: (data) => {
      // redirection to view Login if the reinitialization of password is ok
      toast.success('Mot de passe réinitialisé :)');
      history.push({
        pathname: '/login',
      });
    },
  });

  // mark the inputs related to the specified error
  const errorPasswordField = errorFind(errors, 'passwordField');
  const errorPasswordFieldConfirm = errorFind(errors, 'passwordFieldConfirm');

  const loading = loadingSpecificators || loadingChangePassword;

  return (
    <div className="resetPassword centered-block" data-testid="ResetPassword">
      <div className="form-container">
        {/* Conditionnal display according to the security code entry */}
        {isSamePwd === true ? (
          <section>
            <p>Tapez le code de sécurité ci-dessous</p>
            <p>Vous avez droit à un total de trois essais :)</p>
            <VerificationInput
              validChars="0-9"
              removeDefaultStyles
              container={{
                className: 'container',
              }}
              characters={{
                className: 'characters',
              }}
              character={{
                className: 'character',
                classNameInactive: 'character--inactive',
                classNameSelected: 'character--selected',
              }}
              getInputRef={(e) => {
                setInputRef(e);
              }}
            />
            {nbrClick !== LIMIT_TRY ? (
              <Button className="centered-block" data-testid="input-submit" label="Valider" callback={verifyMyCode} />
            ) : (
              <Button
                data-testid="input-submit"
                label="Envoyer un nouveau code"
                callback={() => {
                  setNbrClick(0);
                  sendSecurityCode({
                    variables: { email: history.location.state.email },
                  });
                }}
              />
            )}
          </section>
        ) : (
          <div>
            <div className="centered-block label">
              <h2>Etape de réinitialisation</h2>
            </div>
            <form className={'resetPassword-form ' + (loading ? 'loading' : '')} noValidate>
              <div className="column">
                <InputPassword
                  data-testid="input-password"
                  label="Entrez votre nouveau mot de passe"
                  name="passwordField"
                  autoComplete="password"
                  minLength={FORM_MINLENGTH}
                  placeholder="••••••••"
                  onChange={handleChange}
                  value={passwordField}
                  error={errorPasswordField}
                />
                <ValidationRules checkValidity={setIsPasswordValid} rules={securityLevels} password={passwordField} />
                {isPasswordValid && (
                  <div>
                    <InputPassword
                      data-testid="input-password"
                      label="Confirmez votre nouveau mot de passe"
                      name="passwordFieldConfirm"
                      autoComplete="password"
                      minLength={FORM_MINLENGTH}
                      placeholder="••••••••"
                      onChange={handleConfirmChange}
                      value={passwordFieldConfirm}
                      error={errorPasswordFieldConfirm}
                    />
                    <Button
                      data-testid="input-submit"
                      label="Valider"
                      plain={true}
                      callback={() => isSamePassword(passwordField, passwordFieldConfirm, isPasswordValid)}
                    />
                  </div>
                )}
              </div>
            </form>
            <div className="centered-block">
              <HistoryLink to="/login">{'Retourner à la page du login'}</HistoryLink>
            </div>
          </div>
        )}
        {!loading && errors && renderErrors(errors.graphql)}
        {!loading && errors && renderErrors(errors.network)}
      </div>
    </div>
  );
};
