import { useQuery } from '@apollo/react-hooks';
import dateformat from 'dateformat';
import React, { useState } from 'react';
import { Button } from '../../components/Button';
import Input from '../../components/Input';
import { Menu } from '../../components/Menu';
import Select from '../../components/Select';
import { GET_FACILITIES } from '../../graphql/queries/facilities';
import { GET_USERS_OF_FACILITY } from '../../graphql/queries/users';
import { onError, renderErrors } from '../../utils/errorHandler';
import { useForm } from '../../utils/hooks';

export const ManageUsers = (props) => {
  const { history } = props;

  const [facilities, setFacilities] = useState({ ids: [], names: [] });

  const { values, onSubmit, onChange } = useForm(fetchUsers, {
    search: '',
  });

  const [facility, setFacility] = useState({ value: -1, id: null });

  const onChangeFacility = (event) => {
    const value = event.target.value;
    if (value >= 0 && value < facilities.ids.length) setFacility({ value: value, id: facilities.ids[value] });
    else setFacility({ value: -1, id: null });
  };

  const [users, setUsers] = useState([]);

  const [errorsUsers, setErrorsUsers] = useState({});
  const [errorsFacilities, setErrorsFacilities] = useState({});

  const { loading: loadingFacilities } = useQuery(GET_FACILITIES, {
    onCompleted: async ({ facilities: data }) => {
      setFacilities({
        ids: data.map((f) => f.id),
        names: data.map((f) => f.name),
      });
    },
    notifyOnNetworkStatusChange: true,
    onError: (err) => onError(setErrorsFacilities, err),
  });

  const { loading: loadingUsers, refetch: refetchUsers } = useQuery(GET_USERS_OF_FACILITY, {
    variables: { id: facility.id, query: values.search },
    onCompleted: async (data) => {
      if (data) setUsers(data.usersOfFacility);
      setErrorsUsers(null);
    },
    onError: (err) => {
      setUsers({});
      onError(setErrorsUsers, err);
    },
  });

  const onClickUser = (id) => {
    history.push({
      pathname: '/updateUser',
      state: {
        userId: id,
      },
    });
  };
  function fetchUsers() {
    refetchUsers();
  }

  const loading = loadingFacilities;

  return (
    <div className="row whole-height fill">
      <Menu />
      <main className="block column fill">
        <h1 className="marg-b">Gestion des utilisateurs</h1>
        <div className="column middle fill w-80">
          <form className={'row align-b marg-b middle ' + (loading ? 'loading' : '')} onSubmit={onSubmit}>
            <Select
              onChange={onChangeFacility}
              loading={loadingFacilities}
              values={facilities.names}
              value={facility.value}
              name="facility"
              defaultOption="Structure"
            />
            <Input
              className="fill marg-s"
              placeholder="Votre recherche ici"
              name="search"
              value={values.search}
              onChange={onChange}
            />
            <Button plain={true} label="Créer un nouveau" callback={() => history.push(`/addUser`)} />
          </form>
          {loadingUsers && <span className="fill loading"></span>}
          {!loadingUsers && (!users || users.length === 0) && <div>Aucun utilisateur trouvé</div>}
          {!loadingUsers && facility && users && users.length > 0 && (
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Prénom</th>
                  <th>Nom</th>
                  <th>Date d'ajout</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr className="cursed" key={user.id} onClick={() => onClickUser(user.id)}>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{dateformat(user.createdAt, 'dd/mm/yyyy')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {errorsFacilities && errorsFacilities && renderErrors(errorsFacilities.graphql)}
          {errorsFacilities && errorsFacilities && renderErrors(errorsFacilities.network)}
          {errorsUsers && errorsUsers && renderErrors(errorsUsers.graphql)}
          {errorsUsers && errorsUsers && renderErrors(errorsUsers.network)}
        </div>
      </main>
    </div>
  );
};
