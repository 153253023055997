import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function HistoryLink(props) {
  const history = useHistory();

  const { children, to, state, search, ...rest } = props;
  return (
    <a
      href={to}
      className={'link'}
      onClick={(event) => {
        event.preventDefault();
        if (to)
          history.push({
            pathname: to,
            search,
            state,
          });
        else history.goBack();
      }}
      data-testid="HistoryLink"
      {...rest}
    >
      {children}
    </a>
  );
}

HistoryLink.defaultProps = {
  to: '/',
};

HistoryLink.propTypes = {
  to: PropTypes.string,
  state: PropTypes.any,
};
