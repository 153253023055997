import React from 'react';

export default function MessageBubble(props) {
  const { style, label, value, text, error = false } = props;

  return (
    <div className={`msg ${error ? 'msg-error' : ''}`} style={style} {...props}>
      <div className="row space-between marg-b">
        {label != null && <h4>{label}</h4>}
        {value != null && <span>{value}</span>}
      </div>
      {text && <p>{text}</p>}
    </div>
  );
}
