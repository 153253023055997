import { gql } from 'apollo-boost';

export const FRAGMENT_USER = gql`
  fragment FragmentUser on User {
    __typename
    id
    firstname
    lastname
    createdAt
    facility
    email
    role
    vehiclePower
    companyCar
    mealTicket
    mealPack
    dob
    designation
    commissionRate
    contribution
    status
    address {
      address1
      address2
      postalCode
      city
      state
    }
  }
`;
