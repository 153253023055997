import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../Button';
import './Card.scss';

export const Card = (props) => {
  const { icon: Icon, label, text, buttonLabel, buttonCallback } = props;

  return (
    <div className="card">
      <div className="block column middle">
        {!Icon ? null : <Icon />}
        <div className="infos">
          <h2>{label}</h2>
          <p>{text}</p>
        </div>
        <Button label={buttonLabel} callback={buttonCallback} />
      </div>
    </div>
  );
};

Card.defaultProps = {
  label: 'Title',
};

Card.propTypes = {
  label: PropTypes.string,
  infos: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonCallback: PropTypes.func,
};
